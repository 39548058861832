import { useState, useRef, useEffect } from 'react';
import { api } from '../../config/api';
import { audioManager } from '../../services/audioManager';

// AudioURL-Manager für persistentes Speichern der Blob-URLs
const AudioURLManager = {
  getStorageKey: (url) => `audio_blob_${url}`,

  // Speichert Blob-URL für eine Audio-URL
  saveURL: (audioUrl, blobUrl) => {
    try {
      sessionStorage.setItem(
        AudioURLManager.getStorageKey(audioUrl),
        blobUrl
      );
    } catch (e) {
    }
  },

  // Holt gespeicherte Blob-URL
  getURL: (audioUrl) => {
    try {
      return sessionStorage.getItem(AudioURLManager.getStorageKey(audioUrl));
    } catch (e) {
      return null;
    }
  },

  // Entfernt Blob-URL aus Storage
  removeURL: (audioUrl) => {
    try {
      sessionStorage.removeItem(AudioURLManager.getStorageKey(audioUrl));
    } catch (e) {
    }
  }
};

export const useAudioPlayer = (audioUrl) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);

  const audioRef = useRef(new Audio());
  const playerIdRef = useRef(Math.random().toString(36).substr(2, 9));

  useEffect(() => {
    if (!audioUrl) return;

    const audio = audioRef.current;

    // Reset current audio
    audio.pause();
    audio.currentTime = 0;

    // Set new audio source and load metadata
    const loadAudio = async () => {
      try {
        audio.src = audioUrl;
        await audio.load(); // Force loading of audio file

        // Wait for metadata to be loaded
        if (audio.readyState < 1) { // If metadata not loaded yet
          await new Promise((resolve, reject) => {
            audio.addEventListener('loadedmetadata', resolve, { once: true });
            audio.addEventListener('error', reject, { once: true });
          });
        }

        setDuration(audio.duration);
      } catch (e) {
        setError(new Error('Fehler beim Laden der Audio-Datei'));
      }
    };

    loadAudio();

    return () => {
      audio.pause();
      audio.src = '';
    };
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      audio.currentTime = 0;
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  // Audio Manager Integration
  useEffect(() => {
    const unsubscribe = audioManager.subscribe((exceptPlayer) => {
      if (exceptPlayer !== playerIdRef.current && isPlaying) {
        const audio = audioRef.current;
        audio.pause();
        setIsPlaying(false);
      }
    });

    return () => {
      unsubscribe();
      if (audioManager.currentlyPlaying === playerIdRef.current) {
        audioManager.stopCurrent();
      }
    };
  }, [isPlaying]);

  const togglePlayback = async () => {
    if (error) return;

    try {
      const audio = audioRef.current;

      if (isPlaying) {
        audio.pause();
        audioManager.stopCurrent();
        setIsPlaying(false);
      } else {
        audioManager.setCurrentlyPlaying(playerIdRef.current);
        await audio.play();
        setIsPlaying(true);
      }
    } catch (err) {
      setError(new Error('Wiedergabe fehlgeschlagen'));
      audioManager.stopCurrent();
    }
  };

  return {
    isPlaying,
    duration,
    currentTime,
    error,
    isLoading: !duration,
    togglePlayback
  };
};