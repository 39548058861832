import React from 'react';
import { Check, ChevronLeft, ChevronRight } from 'lucide-react';

const AVATARS = [
  { id: 1, src: '/Avatars/mentor1.png', alt: 'Coach 1' },
  { id: 2, src: '/Avatars/mentor2.png', alt: 'Coach 2' },
  { id: 3, src: '/Avatars/mentor3.png', alt: 'Coach 3' },
  { id: 4, src: '/Avatars/mentor4.png', alt: 'Coach 4' },
  { id: 5, src: '/Avatars/mentor5.png', alt: 'Coach 5' },
  { id: 6, src: '/Avatars/mentor6.png', alt: 'Coach 6' }
];

export const AvatarSelector = ({
  selectedAvatar,
  onAvatarSelect,
  variant = 'carousel'
}) => {
  const handlePrevAvatar = () => {
    const newValue = selectedAvatar === 1 ? AVATARS.length : selectedAvatar - 1;
    onAvatarSelect(newValue);
  };

  const handleNextAvatar = () => {
    const newValue = selectedAvatar === AVATARS.length ? 1 : selectedAvatar + 1;
    onAvatarSelect(newValue);
  };

  if (variant === 'carousel') {
    return (
      <>
        <div className="relative flex justify-center mb-6">
          <button
            onClick={handlePrevAvatar}
            className="absolute left-0 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50"
            type="button"
            aria-label="Previous mentor"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>

          <div className="w-48 h-48 relative">
            <img
              src={AVATARS.find(a => a.id === selectedAvatar)?.src}
              alt={AVATARS.find(a => a.id === selectedAvatar)?.alt}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>

          <button
            onClick={handleNextAvatar}
            className="absolute right-0 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50"
            type="button"
            aria-label="Next mentor"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>

        <div className="flex justify-center gap-2 mb-6">
          {AVATARS.map(avatar => (
            <button
              key={avatar.id}
              onClick={() => onAvatarSelect(avatar.id)}
              className={`w-2 h-2 rounded-full ${
                selectedAvatar === avatar.id ? 'bg-teal-500' : 'bg-gray-300'
              }`}
              type="button"
              aria-label={`Select ${avatar.alt}`}
              aria-pressed={selectedAvatar === avatar.id}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="grid grid-cols-5 gap-4">
      {AVATARS.map((avatar) => (
        <button
          key={avatar.id}
          onClick={() => onAvatarSelect(avatar.id)}
          className={`relative rounded-lg overflow-hidden border-2 aspect-square ${
            selectedAvatar === avatar.id
              ? 'border-teal-500'
              : 'border-transparent'
          }`}
          aria-label={`Select ${avatar.alt}`}
          aria-pressed={selectedAvatar === avatar.id}
        >
          <img
            src={avatar.src}
            alt={avatar.alt}
            className="w-full h-full object-cover"
          />
          {selectedAvatar === avatar.id && (
            <div className="absolute bottom-1 right-1 bg-teal-500 rounded-full p-1">
              <Check className="w-3 h-3 text-white" />
            </div>
          )}
        </button>
      ))}
    </div>
  );
};