import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Mic, MessageSquare } from 'lucide-react';
import { VoiceSelector } from '../components/MentorPreferences/VoiceSelector';
import { AddressTypeSelector } from '../components/MentorPreferences/AddressTypeSelector';
import { AvatarSelector } from '../components/MentorPreferences/AvatarSelector';
import useStore from '../services/userStore';

import {
  PageLayout,
  SettingsCard,
  LoadingSpinner,
  StatusMessage,
  ActionButton,
  FormInput,
} from '../Layout/PreferenceLayout';

const MentorSelectScreen = () => {
  const navigate = useNavigate();
  const {
    mentorName,
    selectedAvatar,
    selectedVoice,
    addressType,
    isLoading,
    error,
    isAuthenticated,
    setMentorPreferences,
    loadFromStorage,
    syncWithServer,
    saveToServer
  } = useStore();

  const [isNameValid, setIsNameValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      const syncSuccess = await syncWithServer();
      if (!syncSuccess) {
        navigate('/login');
        return;
      }
      loadFromStorage();
    };

    initializeData();
  }, [loadFromStorage, navigate, syncWithServer]);

  useEffect(() => {
    const nameRegex = /^[a-zA-ZäöüÄÖÜß\s-]{2,}$/;
    setIsNameValid(nameRegex.test(mentorName.trim()));
  }, [mentorName]);

  const handleStart = async () => {
    if (!isNameValid) return;

    try {
      setIsSaving(true);
      const success = await saveToServer();

      if (success) {
        navigate('/chat');
      } else if (!isAuthenticated) {
        navigate('/login');
      }
    } catch (err) {
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout>
      <div className="flex justify-center mb-8">
        <h1
          className="text-5xl font-bold bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent"
          role="banner"
        >
          ReflectAI
        </h1>
      </div>

      {error && (
        <StatusMessage
          type="error"
          message={error}
        />
      )}

      {!isAuthenticated && (
        <StatusMessage
          type="warning"
          message="Du bist nicht eingeloggt. Die Einstellungen werden nur lokal gespeichert."
        />
      )}

      <div className="space-y-6">
        <SettingsCard
          icon={User}
          title="Coach Auswahl"
          description="Wähle deinen Coach aus"
        >
          <div className="space-y-6">
            <AvatarSelector
              selectedAvatar={selectedAvatar}
              onAvatarSelect={(avatar) => setMentorPreferences({ selectedAvatar: avatar })}
              variant="carousel"
            />
            <FormInput
              label="Name des Coaches"
              value={mentorName}
              onChange={(e) => setMentorPreferences({ mentorName: e.target.value })}
              placeholder="Wie lautet sein / ihr Name?"
              error={mentorName && !isNameValid}
              errorMessage="Bitte gebe einen gültigen Namen ein (mind. 2 Buchstaben, keine Zahlen oder Sonderzeichen)"
            />
          </div>
        </SettingsCard>

        <SettingsCard
          icon={Mic}
          title="Stimme Einstellungen"
          description="Wähle die Stimme deines Coaches"
        >
          <VoiceSelector
            selectedVoice={selectedVoice}
            onVoiceSelect={(voice) => setMentorPreferences({ selectedVoice: voice })}
            addressType={addressType}
          />
        </SettingsCard>

        <SettingsCard
          icon={MessageSquare}
          title="Anrede Einstellungen"
          description="Wie soll dich dein Coach ansprechen?"
        >
          <AddressTypeSelector
            addressType={addressType}
            onAddressTypeChange={(type) => setMentorPreferences({ addressType: type })}
          />
        </SettingsCard>
      </div>

      <div className="flex justify-end mt-8">
        <ActionButton
          onClick={handleStart}
          disabled={!isNameValid}
          isLoading={isSaving}
        >
          Weiter zum Chat
        </ActionButton>
      </div>
    </PageLayout>
  );
};

export default MentorSelectScreen;