import React, { useState, useEffect, useRef } from 'react';
import { PlayCircle, StopCircle } from 'lucide-react';
import { GERMAN_VOICES } from '../../config/constants';

export const VoiceSelector = ({ selectedVoice, onVoiceSelect, addressType }) => {
  const [playingVoices, setPlayingVoices] = useState({});
  const audioRefs = useRef({});

  useEffect(() => {
    Object.keys(audioRefs.current).forEach(key => {
      if (audioRefs.current[key]) {
        audioRefs.current[key].pause();
        audioRefs.current[key].currentTime = 0;
      }
    });
    setPlayingVoices({});
    audioRefs.current = {};
  }, [addressType]);

  const handleVoicePreview = (voiceId) => {
    if (playingVoices[voiceId]) {
      const audioKey = `${voiceId}_${addressType}`;
      if (audioRefs.current[audioKey]) {
        audioRefs.current[audioKey].pause();
        audioRefs.current[audioKey].currentTime = 0;
      }
      setPlayingVoices(prev => ({ ...prev, [voiceId]: false }));
      return;
    }

    Object.keys(audioRefs.current).forEach(key => {
      if (audioRefs.current[key]) {
        audioRefs.current[key].pause();
        audioRefs.current[key].currentTime = 0;
      }
    });

    setPlayingVoices(prev => Object.keys(prev).reduce((acc, id) => ({
      ...acc,
      [id]: id === voiceId ? true : false
    }), {}));

    const voice = GERMAN_VOICES.find(v => v.id === voiceId);
    if (!voice) return;

    const currentPreviewUrl = voice.previewUrl[addressType];

    if (!audioRefs.current[`${voiceId}_${addressType}`]) {
      audioRefs.current[`${voiceId}_${addressType}`] = new Audio(currentPreviewUrl);
      audioRefs.current[`${voiceId}_${addressType}`].onended = () => {
        setPlayingVoices(prev => ({ ...prev, [voiceId]: false }));
      };
    }

    audioRefs.current[`${voiceId}_${addressType}`].play().catch(error => {
      setPlayingVoices(prev => ({ ...prev, [voiceId]: false }));
    });
  };

  return (
    <div className="space-y-2">
      {GERMAN_VOICES.map((voice) => (
        <div
          key={voice.id}
          className={`flex items-center justify-between p-3 border rounded-lg cursor-pointer hover:bg-gray-50 ${
            selectedVoice?.id === voice.id ? 'border-teal-500' : 'border-gray-200'
          }`}
          onClick={() => onVoiceSelect(voice)}
        >
          <div className="flex items-center">
            <input
              type="radio"
              checked={selectedVoice?.id === voice.id}
              onChange={() => onVoiceSelect(voice)}
              className="h-4 w-4 text-teal-500 focus:ring-teal-500"
            />
            <label className="ml-3">
              <span className="block text-sm font-medium text-gray-700">{voice.name}</span>
              <span className="block text-xs text-gray-500">
                {voice.gender === 'female' ? 'Weiblich' : 'Männlich'}
              </span>
            </label>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleVoicePreview(voice.id);
            }}
            className="p-2 text-gray-600 hover:text-teal-500"
          >
            {playingVoices[voice.id] ? (
              <StopCircle className="w-5 h-5" />
            ) : (
              <PlayCircle className="w-5 h-5" />
            )}
          </button>
        </div>
      ))}
    </div>
  );
};