// src/utils/errors/errorMapping.js
import { AppError } from './AppError';

export const ERROR_CODES = {
  // Authentication Errors (1000-1999)
  AUTH_001: 'Ungültige Anmeldeinformationen',
  AUTH_002: 'Ihr Sitzungstoken ist abgelaufen',
  AUTH_003: 'Ungültiger Authentifizierungstoken',
  AUTH_004: 'Authentifizierung erforderlich',
  AUTH_005: 'Diese E-Mail-Adresse wird bereits verwendet',
  AUTH_006: 'Keine Berechtigung für diese Aktion',

  // Validation Errors (2000-2999)
  VAL_001: 'Ungültige Eingabedaten',
  VAL_002: 'Pflichtfelder fehlen',
  VAL_003: 'Ungültige E-Mail-Adresse',
  VAL_004: 'Ungültiges Passwort',
  VAL_005: 'Ungültiges Datenformat',

  // Preference Validation Errors (2100-2199)
  VAL_101: 'Ungültige Avatar-ID',
  VAL_102: 'Ungültige Stimmen-ID',
  VAL_103: 'Ungültige Anredeform',
  VAL_104: 'Name überschreitet maximale Länge',

  // Database Errors (3000-3999)
  DB_001: 'Datenbankfehler aufgetreten',
  DB_002: 'Datensatz nicht gefunden',
  DB_003: 'Datensatz existiert bereits',
  DB_004: 'Integritätsfehler in der Datenbank',

  // Database Mapping Errors (3100-3199)
  DB_101: 'Verknüpfung existiert bereits',
  DB_102: 'Ungültige Verknüpfung',

  // File Handling Errors (4000-4999)
  FILE_001: 'Datei nicht gefunden',
  FILE_002: 'Ungültiger Dateityp',
  FILE_003: 'Datei ist zu groß',
  FILE_004: 'Fehler beim Hochladen der Datei',

  // Audio File Errors (4100-4199)
  FILE_101: 'Keine Audiodatei gefunden',
  FILE_102: 'Ungültiger Audio-Dateityp',
  FILE_103: 'Audiodatei überschreitet maximale Größe',
  FILE_104: 'Leere Audiodatei',

  // External Service Errors (5000-5999)
  EXT_001: 'Externer Service nicht verfügbar',
  EXT_002: 'Zeitüberschreitung beim externen Service',
  EXT_003: 'Fehler im externen Service',

  // General Errors (9000-9999)
  GEN_001: 'Ein unerwarteter Fehler ist aufgetreten',
  GEN_002: 'Interner Serverfehler',
  GEN_003: 'Anfragelimit überschritten',

  // Legacy Chat Errors (to be migrated)
  CHAT_001: 'Chat konnte nicht erstellt werden',
  CHAT_002: 'Nachricht konnte nicht gesendet werden',
  CHAT_003: 'Sprachnachricht konnte nicht verarbeitet werden',
  CHAT_004: 'Chat nicht gefunden',
  CHAT_005: 'Fehler beim Laden der Chat-Historie',
  CHAT_006: 'Ungültige Chat-Konfiguration',

  // Legacy API Errors (to be migrated)
  API_001: 'Ein unerwarteter Fehler ist aufgetreten',
  API_002: 'Keine Verbindung zum Server möglich',
  API_003: 'Zu viele Anfragen',
  API_004: 'Zeitüberschreitung der Anfrage'
};

export const mapErrorToMessage = (error) => {
  if (error.name === 'AuthError') {

    // Direkt die Fehlermeldung aus den Details verwenden, wenn vorhanden
    if (error.details?.error?.message) {
      return {
        title: 'Fehler bei der Authentifizierung',
        message: error.details.error.message,
        details: error.details,
        status: error.status
      };
    }

    // Fehlercode-spezifische Behandlung
    switch (error.code) {
      case 'AUTH_001':
        return {
          title: 'Ungültige Anmeldedaten',
          message: 'Ungültige E-Mail oder Passwort',
          details: error.details,
          status: error.status
        };
      case 'AUTH_002':
        return {
          title: 'Sitzung abgelaufen',
          message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
          details: error.details,
          status: error.status
        };
      case 'AUTH_005':
        return {
          title: 'Registrierungsfehler',
          message: 'Diese E-Mail-Adresse wird bereits verwendet',
          details: error.details,
          status: error.status
        };
      case 'AUTH_006':
        return {
          title: 'Keine Berechtigung',
          message: 'Sie haben keine Berechtigung für diese Aktion',
          details: error.details,
          status: error.status
        };
      default:
        return {
          title: 'Authentifizierungsfehler',
          message: error.message || 'Ein unerwarteter Fehler ist aufgetreten',
          details: error.details,
          status: error.status
        };
    }
  }

  if (error.code?.startsWith('FILE_')) {
    const title = error.code.startsWith('FILE_1') ? 'Audio-Fehler' : 'Datei-Fehler';
    return {
      title,
      message: ERROR_CODES[error.code] || 'Fehler bei der Dateiverarbeitung',
      details: error.details,
      status: error.status
    };
  }

  if (error.code?.startsWith('VAL_')) {
    return {
      title: 'Validierungsfehler',
      message: ERROR_CODES[error.code] || 'Ungültige Eingabedaten',
      details: error.details,
      status: error.status
    };
  }

  if (error.code?.startsWith('DB_')) {
    return {
      title: 'Datenbankfehler',
      message: ERROR_CODES[error.code] || 'Ein Fehler ist bei der Datenbankoperation aufgetreten',
      details: error.details,
      status: error.status
    };
  }

  // Default Error
  return {
    title: 'Ein Fehler ist aufgetreten',
    message: error.message || ERROR_CODES[error.code] || 'Bitte versuchen Sie es später erneut',
    details: error.details || {},
    status: error.status || 500
  };
};