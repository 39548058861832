import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { useFormValidation } from '../hooks/useFormValidation';
import { AuthService } from '../config/auth';
import { PasswordInput } from '../components/Registration/PasswordInput';
import Layout from '../Layout/Layout';
import LoadingSubmitButton from '../components/LoadingSubmitButton';
import { useErrorHandler } from '../hooks/useErrorHandler';

const NewPasswordScreen = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [success, setSuccess] = useState('');
  const { error, handleError, clearError } = useErrorHandler();

  const {
    formData,
    isLoading,
    setIsLoading,
    handleChange,
    validateFormData
  } = useFormValidation('newPassword', {
    password: '',
    confirmPassword: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) return;

    setIsLoading(true);
    clearError();

    try {
      const response = await AuthService.setNewPassword(token, formData.password);

      if (response.status === 200) {
        setSuccess('Dein Passwort wurde erfolgreich zurückgesetzt.');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        handleError({
          code: 'VAL_004',
          message: response.data.error || 'Fehler beim Zurücksetzen des Passworts',
          details: {
            error: 'Bitte versuchen Sie es später erneut.'
          }
        });
      }
    } catch (err) {
      if (err.response?.data?.error) {
        handleError(err.response.data.error);
      } else {
        handleError({
          code: 'VAL_004',
          message: 'Fehler beim Zurücksetzen des Passworts',
          details: {
            error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
          }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return (
      <Layout
        title="Fehler"
        subtitle="Kein gültiger Reset-Token gefunden."
        error={{
          code: 'AUTH_006',
          message: 'Kein gültiger Reset-Token gefunden.',
          details: {
            error: 'Bitte stellen Sie sicher, dass Sie den korrekten Link aus der E-Mail verwendet haben.'
          }
        }}
      />
    );
  }

  return (
    <Layout
      title="Neues Passwort setzen"
      subtitle="Bitte gib dein neues Passwort ein."
      error={error}
      success={success}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
            Neues Passwort
          </label>
          <PasswordInput
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            disabled={isLoading}
            showStrengthIndicator={true}
            showRequirements={true}
          />
        </div>

        <div>
          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
            Passwort bestätigen
          </label>
          <PasswordInput
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            disabled={isLoading}
          />
          {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword && (
            <p className="mt-1 text-sm text-red-600">
              Die Passwörter stimmen nicht überein
            </p>
          )}
        </div>

        <LoadingSubmitButton isLoading={isLoading}>
          Passwort ändern
        </LoadingSubmitButton>
      </form>
    </Layout>
  );
};

export default NewPasswordScreen;