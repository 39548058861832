import React, { useState } from 'react';
import { Clock } from 'lucide-react';

const SessionDurationInput = ({ value, onChange, disabled }) => {
  const [minutes, setMinutes] = useState(value || 15);

  const handleChange = (newValue) => {
    const cleanValue = Math.max(1, Math.min(480, newValue)); // Limit zwischen 1 und 480 Minuten (8 Stunden)
    setMinutes(cleanValue);
    onChange(cleanValue);
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Dauer der Session
      </label>

      <div className="flex items-center space-x-3">
        <div className="relative flex-1 max-w-[130px]">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Clock className="h-4 w-4 text-gray-400" />
          </div>
          <input
            type="number"
            value={minutes}
            onChange={(e) => handleChange(parseInt(e.target.value) || 0)}
            className="block w-full pl-10 pr-12 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            min="1"
            max="480"
            disabled={disabled}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-sm text-gray-500">
            Min
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionDurationInput;