import { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useAudioRecording = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const timerRef = useRef(null);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);

  const getSupportedMimeType = () => {
    const types = [
      'audio/webm',
      'audio/mp4',
      'audio/aac',
      'audio/wav',
      'audio/ogg'
    ];

    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }

    return '';
  };

  const startTimer = useCallback(() => {
    timerRef.current = setInterval(() => {
      setRecordingTime(prev => prev + 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const cleanup = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    chunksRef.current = [];
  }, []);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const startRecording = useCallback(async () => {
    try {
      cleanup();

      // Create AudioContext only when starting recording
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      const constraints = {
        audio: {
          sampleRate: 44100,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;

      const mimeType = getSupportedMimeType();
      const options = mimeType ? { mimeType } : undefined;

      try {
        mediaRecorderRef.current = new MediaRecorder(stream, options);
      } catch (e) {
        mediaRecorderRef.current = new MediaRecorder(stream);
      }

      chunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blobType = mediaRecorderRef.current.mimeType;
        const audioBlob = new Blob(chunksRef.current, { type: blobType });
        setRecordedBlob(audioBlob);
        cleanup();
      };

      mediaRecorderRef.current.start(10);
      setIsRecording(true);
      startTimer();

    } catch (error) {
      cleanup();
    }
  }, [startTimer, cleanup]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      stopTimer();
      setRecordingTime(0);
    }
  }, [isRecording, stopTimer]);

  const resetRecording = useCallback(() => {
    cleanup();
    setRecordedBlob(null);
    setRecordingTime(0);
  }, [cleanup]);

  return {
    isRecording,
    recordingTime,
    recordedBlob,
    startRecording,
    stopRecording,
    resetRecording
  };
};

export const useAudioPlayback = (audioBlob) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPause = useCallback(() => {
    if (!audioRef.current && audioBlob) {
      const blobUrl = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(blobUrl);
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
    }

    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  }, [audioBlob, isPlaying]);

  const stopPlayback = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
  }, []);

  return {
    isPlaying,
    handlePlayPause,
    stopPlayback
  };
};

export const useMessageSubmission = (sendMessage, resetRecording, stopPlayback) => {
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    setMessage('');
    if (resetRecording) {
      resetRecording();
    }
    if (stopPlayback) {
      stopPlayback();
    }
  }, [location.pathname, resetRecording, stopPlayback]);

  const handleSubmit = useCallback(async (e, recordedBlob) => {
    e.preventDefault();
    if ((!message.trim() && !recordedBlob)) return;

    const messageToSend = message.trim();

    if (recordedBlob) {
      try {
        const extension = recordedBlob.type.split('/')[1] || 'webm';
        const filename = `${crypto.randomUUID()}.${extension}`;
        const formData = new FormData();
        formData.append('audio', recordedBlob, filename);

        resetRecording();
        stopPlayback();

        await sendMessage(formData, false, true);
      } catch (error) {
        throw error;
      }
    } else {
      setMessage('');
      try {
        await sendMessage(messageToSend, false, false);
      } catch (error) {
        throw error;
      }
    }
  }, [message, sendMessage, resetRecording, stopPlayback]);

  return {
    message,
    setMessage,
    handleSubmit
  };
};


export const useTimeFormatter = () => {
  return useCallback((seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  }, []);
};