// userStore.js
import { create } from 'zustand';
import { GERMAN_VOICES } from '../config/constants';
import { api } from '../config/api';
import Cookies from 'js-cookie';
import { AppError } from '../utils/errors';

// ================ Constants ================
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours
const COOKIE_OPTIONS = {
  expires: 1,
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'Strict'
};

// ================ Initial State ================
const initialState = {
  firstName: '',
  lastName: '',
  mentorName: '',
  initials: '',
  selectedAvatar: 1,
  selectedVoice: GERMAN_VOICES[0],
  addressType: 'du',
  isLoading: false,
  isAuthenticated: false,
  isSaved: false,
  error: null
};

// ================ Error Handling ================
const createErrorObject = (error) => {
  if (error instanceof AppError) return error;

  if (error.response) {
    return new AppError(
      error.response.data?.error?.code || 'USER_001',
      error.response.data?.error?.message || 'Ein Fehler ist aufgetreten',
      error.response.data?.error?.details || {},
      error.response.status
    );
  }

  if (error.request) {
    return new AppError(
      'NETWORK_001',
      'Keine Verbindung zum Server möglich',
      {},
      0
    );
  }

  return new AppError(
    'USER_001',
    error.message || 'Ein unerwarteter Fehler ist aufgetreten',
    {},
    500
  );
};

// ================ Storage Utilities ================
const storageUtils = {
  save: (data) => {
    const dataToCache = {
      mentorName: data.mentorName,
      selectedAvatar: data.selectedAvatar,
      selectedVoice: typeof data.selectedVoice === 'object' ? data.selectedVoice.id : data.selectedVoice,
      addressType: data.addressType,
      timestamp: Date.now()
    };
    localStorage.setItem('mentor_prefs_cache', JSON.stringify(dataToCache));
  },

  load: () => {
    const cachedData = localStorage.getItem('mentor_prefs_cache');
    if (!cachedData) return null;

    const data = JSON.parse(cachedData);
    if (Date.now() - data.timestamp >= CACHE_DURATION) {
      localStorage.removeItem('mentor_prefs_cache');
      return null;
    }

    return {
      ...data,
      selectedVoice: GERMAN_VOICES.find(v => v.id === data.selectedVoice) || GERMAN_VOICES[0]
    };
  },

  clear: () => {
    localStorage.removeItem('mentor_prefs_cache');
    Cookies.remove('userInitials', COOKIE_OPTIONS);
  }
};

// ================ Authentication Utilities ================
const authUtils = {
  async checkAuthStatus() {
    try {
      await api.get('/auth/me');
      return true;
    } catch {
      return false;
    }
  },

  handleAuthError(set, get) {
    set({ isAuthenticated: false });
    return false;
  }
};

// ================ Store Creation ================
const useStore = create((set, get) => {
  // ---- Helper Functions ----
  const handleStoreError = (error) => {
    const errorObj = createErrorObject(error);
    set({ error: errorObj });
    return errorObj;
  };

  const updateStateWithPreferences = (prefData, isAuthenticated = true) => {
    const selectedVoice = GERMAN_VOICES.find(
      v => v.id === prefData.voice_id
    ) || GERMAN_VOICES[0];

    const newState = {
      mentorName: prefData.name || '',
      selectedAvatar: prefData.avatar_id || 1,
      selectedVoice,
      addressType: prefData.address_type || 'du',
      isAuthenticated
    };

    set(state => ({ ...state, ...newState }));
    return newState;
  };

  // Return the store object
  return ({
    // ---- Initial State ----
    ...initialState,
    initials: Cookies.get('userInitials') || '',

    // ---- Error Management ----
    setError: (error) => set({ error }),
    clearError: () => set({ error: null }),

    // ---- User Preferences ----
    setInitials: (initials) => {
      try {
        set({ initials });
        Cookies.set('userInitials', initials, COOKIE_OPTIONS);
      } catch (error) {
        handleStoreError(error);
      }
    },

    setMentorPreferences: (preferences) => {
      try {
        set((state) => {
          const newState = {
            ...state,
            ...preferences,
            selectedVoice: typeof preferences.selectedVoice === 'object'
              ? preferences.selectedVoice
              : GERMAN_VOICES.find(v => v.id === preferences.selectedVoice) || state.selectedVoice
          };
          storageUtils.save(newState);
          return newState;
        });
      } catch (error) {
        handleStoreError(error);
      }
    },

    // ---- Server Synchronization ----
    checkAuthentication: async () => {
      const isAuthenticated = await authUtils.checkAuthStatus();
      set({ isAuthenticated });
      return isAuthenticated;
    },

    syncWithServer: async () => {
      set({ isLoading: true, error: null });

      try {
        const { data, status } = await api.get('/users/preferences');

        if (status !== 200 || !data) {
          throw new AppError(
            'USER_002',
            'Keine Benutzerdaten vom Server erhalten',
            {},
            status
          );
        }

        const selectedVoice = GERMAN_VOICES.find(
          v => v.id === data.mentor_preferences?.voice_id
        ) || GERMAN_VOICES[0];

        set({
          firstName: data.first_name || '',
          lastName: data.last_name || '',
          mentorName: data.mentor_preferences?.name || '',
          selectedAvatar: data.mentor_preferences?.avatar_id || 1,
          selectedVoice,
          addressType: data.mentor_preferences?.address_type || 'du',
          isAuthenticated: true,
          error: null
        });

        storageUtils.save(get());
        return true;

      } catch (error) {
        if (error?.response?.status === 401) {
          return authUtils.handleAuthError(set, get);
        }

        handleStoreError(error);
        return false;
      } finally {
        set({ isLoading: false });
      }
    },

    saveToServer: async () => {
      const state = get();
      set({ isLoading: true, error: null });

      try {
        if (!state.mentorName?.trim()) {
          throw new AppError(
            'VALIDATION_001',
            'Bitte geben Sie einen Namen für den Coach ein',
            {},
            400
          );
        }

        const mentorPreferences = {
          name: state.mentorName.trim(),
          avatar_id: state.selectedAvatar,
          voice_id: typeof state.selectedVoice === 'object' ? state.selectedVoice.id : state.selectedVoice,
          address_type: state.addressType
        };

        const response = await api.put('/users/preferences', {
          mentor_preferences: mentorPreferences
        });

        if (response.status === 200 && response.data?.message === 'Einstellungen erfolgreich aktualisiert') {
          set({
            isAuthenticated: true,
            error: null,
            isSaved: true
          });

          if (response.data?.mentor_preferences) {
            const selectedVoice = GERMAN_VOICES.find(
              v => v.id === response.data.mentor_preferences.voice_id
            ) || state.selectedVoice;

            set(state => ({
              ...state,
              mentorName: response.data.mentor_preferences.name,
              selectedAvatar: response.data.mentor_preferences.avatar_id,
              selectedVoice,
              addressType: response.data.mentor_preferences.address_type
            }));
          }

          storageUtils.save(get());
          setTimeout(() => set({ isSaved: false }), 2000);
          return true;
        }

        throw new AppError(
          'USER_003',
          'Unerwartete Serverantwort',
          { serverResponse: response.data },
          response.status
        );

      } catch (error) {
        if (error?.response?.status === 401) {
          return authUtils.handleAuthError(set, get);
        }

        const errorObj = createErrorObject(error);
        set({ error: errorObj });
        throw errorObj;
      } finally {
        set({ isLoading: false });
      }
    },

    // ---- Storage Management ----
    loadFromStorage: () => {
      try {
        const data = storageUtils.load();
        if (!data) return false;

        set(state => ({ ...state, ...data }));
        return true;
      } catch (error) {
        handleStoreError(error);
        return false;
      }
    },

    clearUserData: () => {
      try {
        set(initialState);
        storageUtils.clear();
      } catch (error) {
        handleStoreError(error);
      }
    }
  });
});

export default useStore;