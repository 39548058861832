import React from 'react';
import { useChatApi } from '../../hooks/useChatApi';
import { useChat } from '../../hooks/useChat';
import { MessageInput, RecordingState, RecordedPreview } from './ChatInputUI';
import {
  useAudioRecording,
  useAudioPlayback,
  useMessageSubmission,
  useTimeFormatter
} from './useChatInput';

const ChatInput = () => {
  const { isLoading } = useChat();
  const { sendMessage } = useChatApi();

  const {
    isRecording,
    recordingTime,
    recordedBlob,
    startRecording,
    stopRecording,
    resetRecording
  } = useAudioRecording();

  const {
    isPlaying,
    handlePlayPause,
    stopPlayback
  } = useAudioPlayback(recordedBlob);

  const {
    message,
    setMessage,
    handleSubmit
  } = useMessageSubmission(sendMessage, resetRecording, stopPlayback);

  const formatTime = useTimeFormatter();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSubmit(e, recordedBlob);
    } catch (error) {
    }
  };

  const handleStartRecording = async () => {
    if (isLoading) return;

    try {
      await startRecording();
    } catch (error) {
    }
  };

  const handleDeleteRecording = () => {
    resetRecording();
    stopPlayback();
  };

  return (
    <form onSubmit={handleFormSubmit} className="p-4 border-t bg-white">
      <div className="flex items-center gap-3">
        {isRecording ? (
          <RecordingState
            time={formatTime(recordingTime)}
            onStop={stopRecording}
          />
        ) : recordedBlob ? (
          <RecordedPreview
            onPlayPause={handlePlayPause}
            isPlaying={isPlaying}
            onDelete={handleDeleteRecording}
            onSend={handleFormSubmit}
            disabled={isLoading}
            audioBlob={recordedBlob}
            formatTime={formatTime}
          />
        ) : (
          <MessageInput
            value={message}
            onChange={setMessage}
            onRecord={handleStartRecording}
            disabled={isLoading}
          />
        )}
      </div>
    </form>
  );
};

export default ChatInput;