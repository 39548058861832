import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useChat } from '../../hooks/useChat';
import { useChatApi } from '../../hooks/useChatApi';
import { useChatSidebar } from '../../hooks/useChatSidebar';
import { ErrorAlert, useToast } from '../ErrorComponents';
import { MessageSquare, Pencil, Trash2 } from 'lucide-react';

// Optimized ChatTime component
const ChatTime = React.memo(({ time }) => {
  const formattedTime = new Date(time).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit'
  });

  return (
    <span className="text-xs text-gray-400 tabular-nums">
      {formattedTime}
    </span>
  );
});

// Extracted Chat Actions Component
const ChatActions = React.memo(({ onEdit, onDelete }) => {
  const handleDelete = useCallback((e) => {
    e.stopPropagation();
    if (window.confirm('Möchten Sie diese Konversation wirklich löschen?')) {
      onDelete();
    }
  }, [onDelete]);

  const handleEdit = useCallback((e) => {
    e.stopPropagation();
    onEdit();
  }, [onEdit]);

  return (
    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center gap-1">
      <button
        onClick={handleEdit}
        className="p-1 hover:bg-white/80 rounded-md transition-colors duration-200"
      >
        <Pencil className="w-3.5 h-3.5 text-gray-500" />
      </button>
      <button
        onClick={handleDelete}
        className="p-1 hover:bg-white/80 rounded-md transition-colors duration-200"
      >
        <Trash2 className="w-3.5 h-3.5 text-gray-500" />
      </button>
    </div>
  );
});

// Optimized ChatItem with Stable Handlers
const ChatItem = React.memo(({
  chat,
  isActive,
  onSelect,
  onDelete,
  onRename
}) => {
  const { id, title, last_message, updated_at } = chat;
  const [isEditing, setIsEditing] = React.useState(false);
  const [newTitle, setNewTitle] = React.useState(title);

  const handleSelect = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  const handleRename = useCallback(async () => {
    await onRename(id, newTitle);
    setIsEditing(false);
  }, [id, newTitle, onRename]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleKeyDown = useCallback((e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleRename();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setNewTitle(title);
    }
  }, [handleRename, title]);

  return (
    <div
      onClick={handleSelect}
      className={`mx-2 px-3 py-2.5 rounded-lg cursor-pointer transition-all duration-200
        ${isActive ? 'bg-blue-50 hover:bg-blue-100' : 'hover:bg-gray-50'}
        group`}
    >
      <div className="flex justify-between items-center gap-2">
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            className="text-sm w-full px-2 py-1 rounded border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            onKeyDown={handleKeyDown}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <>
            <span className="text-sm font-medium text-gray-700 truncate flex-1">
              {title || 'Neue Konversation'}
            </span>
            <div className="flex items-center gap-2 flex-shrink-0">
              <ChatTime time={updated_at} />
              <ChatActions
                onEdit={() => setIsEditing(true)}
                onDelete={() => onDelete(id)}
              />
            </div>
          </>
        )}
      </div>
      {last_message && (
        <p className="text-xs text-gray-500 truncate mt-0.5">
          {last_message}
        </p>
      )}
    </div>
  );
});

// Optimized ChatList with virtualization hint
const ChatList = React.memo(({
  chats,
  activeChatId,
  onChatSelect,
  onChatDelete,
  onChatRename
}) => {
  const listRef = useRef(null);

  // Keep date groups for semantic structure but flatten render
  const chatItems = useMemo(() =>
    Object.entries(chats).map(([date, chatGroup]) => (
      <div key={date} className="mb-6">
        <div className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
          {date}
        </div>
        <div className="space-y-1">
          {chatGroup.map(chat => (
            <ChatItem
              key={chat.id}
              chat={chat}
              isActive={chat.id === activeChatId}
              onSelect={onChatSelect}
              onDelete={onChatDelete}
              onRename={onChatRename}
            />
          ))}
        </div>
      </div>
    )),
    [chats, activeChatId, onChatSelect, onChatDelete, onChatRename]
  );

  return (
    <div ref={listRef} className="py-2">
      {chatItems}
    </div>
  );
});

// Optimized ChatSidebar with proper error boundaries and loading states
export const ChatSidebar = () => {
  const { activeChat, setIsSidebarVisible } = useChat();
  const { addToast, ToastContainer } = useToast();
  const {
    chats,
    isLoading,
    error,
    handleChatSelect,
    handleChatDelete,
    handleChatRename,
    clearError,
    refreshChats
  } = useChatSidebar();

  // Stable error handlers with toast feedback
  const wrappedHandleChatSelect = useCallback(async (chatId) => {
    try {
      await handleChatSelect(chatId);
    } catch (err) {
      addToast('Chat konnte nicht geladen werden', 'error');
    }
  }, [handleChatSelect, addToast]);

  const wrappedHandleChatDelete = useCallback(async (chatId) => {
    try {
      await handleChatDelete(chatId);
      addToast('Chat erfolgreich gelöscht', 'success');
    } catch (err) {
      addToast('Chat konnte nicht gelöscht werden', 'error');
    }
  }, [handleChatDelete, addToast]);

  const wrappedHandleChatRename = useCallback(async (chatId, newTitle) => {
    try {
      await handleChatRename(chatId, newTitle);
      addToast('Chat erfolgreich umbenannt', 'success');
    } catch (err) {
      addToast('Chat konnte nicht umbenannt werden', 'error');
    }
  }, [handleChatRename, addToast]);

  // Auto-refresh on visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshChats();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refreshChats]);

  const handleSidebarClose = useCallback(() => {
    setIsSidebarVisible(false);
  }, [setIsSidebarVisible]);

  return (
    <>
      <div className="w-80 h-screen fixed top-0 left-0 bg-white border-r border-gray-200 flex flex-col">
        <div className="h-[73px]" />
        {error && (
          <div className="px-4">
            <ErrorAlert
              error={{
                type: 'error',
                message: error.message || 'Ein Fehler ist aufgetreten',
                details: error.details
              }}
              onClose={clearError}
            />
          </div>
        )}
        <div className="flex-1 overflow-y-auto">
          {isLoading && Object.keys(chats).length === 0 ? (
            <ChatListSkeleton />
          ) : Object.keys(chats).length === 0 ? (
            <div className="h-full flex items-center justify-center">
              <EmptyState />
            </div>
          ) : (
            <ChatList
              chats={chats}
              activeChatId={activeChat}
              onChatSelect={wrappedHandleChatSelect}
              onChatDelete={wrappedHandleChatDelete}
              onChatRename={wrappedHandleChatRename}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const ChatListSkeleton = React.memo(() => (
  <div className="p-4 space-y-4">
    <div className="h-6 bg-gray-100 rounded w-24 mb-6" />
    {[...Array(5)].map((_, i) => (
      <div key={i} className="animate-pulse p-3 rounded-lg">
        <div className="h-4 bg-gray-100 rounded w-3/4 mb-2" />
        <div className="h-3 bg-gray-100 rounded w-1/2" />
      </div>
    ))}
  </div>
));

const EmptyState = React.memo(() => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mb-4">
      <MessageSquare className="w-6 h-6 text-gray-400" />
    </div>
    <p className="text-gray-500 text-sm">
      Keine Konversationen gefunden
    </p>
    <p className="text-gray-400 text-xs mt-2">
      Starte eine neue Konversation, um loszulegen
    </p>
  </div>
));

export default ChatSidebar;