import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../config/api';
import { useErrorHandler } from './useErrorHandler';
import { useChat } from './useChat';

export const useBaseChatApi = () => {
  const navigate = useNavigate();
  const { handleError, clearError } = useErrorHandler();
  const { setMessages, setActiveChat } = useChat();

  const processApiError = useCallback((error) => {
    if (error?.response?.status === 401) {
      navigate('/login');
    }
    handleError(error);
  }, [navigate, handleError]);

  const getMentorPreferences = useCallback(() => ({
    address_type: localStorage.getItem('address_type') || 'sie',
    name: localStorage.getItem('mentor_name') || 'Michael',
    first_name: localStorage.getItem('first_name') || '',
    last_name: localStorage.getItem('last_name') || '',
    voice_id: localStorage.getItem('voice_id') || 'vicki'
  }), []);

  const createNewChat = useCallback(async () => {
    clearError();

    try {
      const { data: chatData } = await api.post('/chat', {
        title: 'Neue Konversation'
      });

      if (!chatData.chat_id) {
        const error = new Error('Keine Chat-ID vom Server erhalten');
        error.response = {
          status: 500,
          data: {
            error: {
              code: 'CHAT_001',
              message: 'Chat konnte nicht erstellt werden'
            }
          }
        };
        throw error;
      }

      setMessages([]);
      setActiveChat(chatData.chat_id);
      return chatData.chat_id;
    } catch (error) {
      processApiError(error);
      throw error;
    }
  }, [setMessages, setActiveChat, processApiError, clearError]);

  return {
    processApiError,
    getMentorPreferences,
    createNewChat,
    clearError
  };
};