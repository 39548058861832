import React from 'react';

const LoadingSubmitButton = ({
  isLoading,
  loadingText = 'Wird verarbeitet...',
  children,
  className = "w-full py-3 bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed",
  type = "submit"
}) => {
  return (
    <button
      type={type}
      disabled={isLoading}
      className={className}
    >
      {isLoading ? (
        <span className="flex items-center justify-center">
          <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></span>
          {loadingText}
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingSubmitButton;