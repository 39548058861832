// TextMessage.jsx
import React from 'react';
import { BaseMessage } from './BaseMessage';

export const TextMessage = ({ message, selectedAvatar }) => {
  return (
    <BaseMessage message={message} selectedAvatar={selectedAvatar}>
      <div className="px-4 py-3 text-[15px] leading-relaxed">
        {message.content}
      </div>
    </BaseMessage>
  );
};