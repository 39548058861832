import React from 'react';

const LoadingMessage = ({ selectedAvatar }) => {
  return (
    <div className="flex justify-start max-w-4xl mx-auto">
      <div className="w-8 h-8 mr-3">
        <img
          src={`/Avatars/mentor${selectedAvatar}.png`}
          alt="Mentor"
          className="w-full h-full rounded-full shadow-sm ring-2 ring-white"
        />
      </div>
      <div className="max-w-[75%]">
        <div className="bg-white shadow-sm rounded-2xl rounded-tl-lg p-4">
          <div className="flex space-x-2 items-center h-6">
            <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                 style={{ animationDelay: '0ms' }} />
            <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                 style={{ animationDelay: '150ms' }} />
            <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                 style={{ animationDelay: '300ms' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMessage;