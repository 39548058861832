import React, { useEffect, useRef, useState, useCallback } from 'react';
import { MessageSquare } from "lucide-react";
import { useNavigate, useParams } from 'react-router-dom';
import { ChatHeader } from '../components/Chat/ChatHeader';
import ChatInput from '../components/ChatInput/ChatInput';
import { ChatMessage } from '../components/Chat/Messages/ChatMessage';
import { ChatSidebar } from '../components/ChatSidebar/ChatSidebar';
import SettingScreen from '../screens/SettingScreen';
import LoadingMessage from '../components/Chat/Messages/LoadingMessage';
import { useChat } from '../hooks/useChat';
import { useSettings } from '../hooks/useSettings';
import { useChatApi } from '../hooks/useChatApi';
import ReflectionSidebar from "../components/Notes/ReflectionSidebar";
import useStore from '../services/userStore';

const ChatScreen = () => {
  const {
    messages,
    isLoading,
    isSidebarVisible,
    setIsSidebarVisible,
    setActiveChat,
    clearMessages,
  } = useChat();

  const { settings, updateSettings } = useSettings();
  const { loadChatMessages, createNewChat, error, clearError } = useChatApi();
  const syncWithServer = useStore(state => state.syncWithServer);

  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isReflectionOpen, setIsReflectionOpen] = useState(false);

  const chatRef = useRef(null);
  const navigate = useNavigate();
  const { chatId } = useParams();
  const isCreatingChat = useRef(false);
  const previousChatId = useRef(null);

  // Vereinfachte Chat-Initialisierung
  const initializeChat = useCallback(async () => {
  if (isCreatingChat.current || isLoadingMessages) return;

  if (!chatId) {
    isCreatingChat.current = true;
    try {
      const newChatId = await createNewChat();
      navigate(`/chat/${newChatId}`);
    } finally {
      isCreatingChat.current = false;
    }
    return;
  }

   if (previousChatId.current !== chatId) {
    setIsLoadingMessages(true);
    try {
      await loadChatMessages(chatId);
      previousChatId.current = chatId;
    } catch (error) {
      // Check if the error is a 404
      if (error.response?.status === 404) {
        // Redirect to /chat on 404
        navigate('/chat');
      }
    } finally {
      setIsLoadingMessages(false);
    }
  }
}, [chatId, loadChatMessages, navigate, createNewChat, isLoadingMessages]);

  // Chat initialisieren wenn sich die ID ändert
  useEffect(() => {
    initializeChat();
  }, [initializeChat]);

  // Nachrichten zurücksetzen wenn sich die Chat-ID ändert
  useEffect(() => {
    if (previousChatId.current !== chatId) {
      clearMessages();
    }
  }, [chatId, clearMessages]);

  // Aktiven Chat aktualisieren
  useEffect(() => {
    if (chatId) {
      setActiveChat(chatId);
    }
  }, [chatId, setActiveChat]);

  useEffect(() => {
    const checkMentorPreferences = async () => {
      const hasStoredPreferences = localStorage.getItem('mentor_prefs_cache');
      if (!hasStoredPreferences) {
        await syncWithServer();
      }
    };

    checkMentorPreferences();
  }, [syncWithServer]);

  // Enhanced scroll to bottom with smooth behavior
  const scrollToBottom = useCallback(() => {
    if (chatRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = chatRef.current;
      const maxScrollTop = scrollHeight - clientHeight;

      // Only smooth scroll if we're close to the bottom
      const isNearBottom = maxScrollTop - scrollTop < 100;

      chatRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: isNearBottom ? 'smooth' : 'auto'
      });
    }
  }, []);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (messages?.length > 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  const handleSettingsUpdate = useCallback(() => {
    updateSettings();
  }, [updateSettings]);

  const handleNewChat = useCallback(async () => {
    if (isCreatingChat.current) return;

    try {
      clearMessages();
      isCreatingChat.current = true;
      const newChatId = await createNewChat();
      navigate(`/chat/${newChatId}`);
    } catch (error) {
    } finally {
      isCreatingChat.current = false;
    }
  }, [clearMessages, createNewChat, navigate]);

  const handleSelectChat = useCallback((selectedChatId) => {
    if (selectedChatId === chatId) return;
    navigate(`/chat/${selectedChatId}`);
  }, [chatId, navigate]);

  const handleToggleSidebar = useCallback(() => {
    setIsSidebarVisible(prev => !prev);
  }, [setIsSidebarVisible]);

  if (showSettings) {
    return (
      <SettingScreen
        onBack={() => {
          setShowSettings(false);
          handleSettingsUpdate();
        }}
      />
    );
  }

  // Ensure messages is always an array and each message has a stable key
  const safeMessages = (Array.isArray(messages) ? messages : []).map((message, index) => ({
    ...message,
    stableKey: message.id || `temp-${index}-${message.timestamp || ''}`
  }));

  return (
    <div className="flex h-screen bg-gray-50">
      {isSidebarVisible && (
        <div className="fixed inset-0 z-40 lg:relative">
          <div
            className="fixed inset-0 bg-black/20 lg:hidden"
            onClick={() => setIsSidebarVisible(false)}
          />
          <div className="absolute inset-y-0 left-0 w-72 bg-white shadow-lg lg:shadow-none lg:relative lg:w-80">
            <ChatSidebar onSelectChat={handleSelectChat} onNewChat={handleNewChat} />
          </div>
        </div>
      )}

      <div className="flex flex-col flex-1 min-w-0">
        <div className="sticky top-0 z-30">
          <ChatHeader
            isReflectionOpen={isReflectionOpen}
            onReflectionToggle={() => setIsReflectionOpen(!isReflectionOpen)}
            onToggleSidebar={handleToggleSidebar}
          />
        </div>

        <div className="flex-1 relative">
          <div
            ref={chatRef}
            className="absolute inset-0 overflow-y-auto py-4 sm:py-6 px-2 sm:px-4 md:px-6 space-y-4 sm:space-y-6 bg-gradient-to-b from-gray-50 to-white scrollbar-thin scrollbar-thumb-gray-200 hover:scrollbar-thumb-gray-300"
          >
            {safeMessages.map((message) => (
              <ChatMessage
                key={message.stableKey}
                message={message}
                selectedAvatar={settings.selectedAvatar}
              />
            ))}

            {(isLoading || isLoadingMessages) && (
              <LoadingMessage selectedAvatar={settings.selectedAvatar} />
            )}

            {safeMessages.length === 0 && !isLoading && !isLoadingMessages && <EmptyState />}
          </div>
        </div>

        <div className="border-t border-gray-100 bg-white">
          <div className="max-w-4xl mx-auto w-full px-2 sm:px-4">
            <ChatInput disabled={isLoadingMessages} />
          </div>
        </div>

        <div
          className={`fixed inset-y-0 right-0 w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
            isReflectionOpen ? 'translate-x-0' : 'translate-x-full'
          } z-50 lg:relative lg:transform-none`}
        >
          <ReflectionSidebar
            isOpen={isReflectionOpen}
            onClose={() => setIsReflectionOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center max-w-md mx-auto">
    <div className="w-16 h-16 bg-blue-50 rounded-full flex items-center justify-center mb-4">
      <MessageSquare className="w-8 h-8 text-blue-400" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">
      Starte eine neue Konversation
    </h3>
    <p className="text-gray-500">
      Schreib einfach los!
      Dein ReflectAI Coach ist hier, um dir zu helfen.
    </p>
  </div>
);

export default ChatScreen;