import React from 'react';

export const PasswordStrengthIndicator = ({ password }) => {
  const getStrength = (pass) => {
    let score = 0;
    if (!pass) return score;
    if (pass.length >= 8) score++;
    if (pass.length >= 12) score++;
    if (/[A-Z]/.test(pass)) score++;
    if (/[0-9]/.test(pass)) score++;
    if (/[^A-Za-z0-9]/.test(pass)) score++;
    return score;
  };

  const strength = getStrength(password);

  const getColor = () => {
    const colors = {
      0: 'bg-gray-200',
      1: 'bg-red-500',
      2: 'bg-orange-500',
      3: 'bg-yellow-500',
      4: 'bg-blue-500',
      5: 'bg-green-500'
    };
    return colors[strength] || colors[0];
  };

  const getLabel = () => {
    const labels = {
      0: 'Kein Passwort',
      1: 'Sehr schwach',
      2: 'Schwach',
      3: 'Mittel',
      4: 'Stark',
      5: 'Sehr stark'
    };
    return labels[strength] || labels[0];
  };

  return (
    <div className="mt-2">
      <div className="flex gap-1 h-1 mb-1">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={`h-full w-full rounded-full ${index < strength ? getColor() : 'bg-gray-200'}`}
          />
        ))}
      </div>
      <p className="text-xs text-gray-600">{getLabel()}</p>
    </div>
  );
};