// contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { AuthService } from '../config/auth';
import { useErrorHandler } from './ErrorContext';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const { error, handleError, clearError } = useErrorHandler(); // Nutze globalen ErrorContext

  const checkAuth = useCallback(async (silent = false) => {
    if (!silent) {
      setLoading(true);
    }

    try {
      const response = await AuthService.getCurrentUser();
      setUser(response.user || null);
    } catch (error) {
      if (!error.message?.includes('No access token') &&
          !error.message?.includes('Refresh Token is required')) {
      }
      // Clear user state on auth failure
      setUser(null);
    } finally {
      setLoading(false);
      setAuthChecked(true);
    }
  }, []);

  // Initial auth check
  useEffect(() => {
    const initialCheck = async () => {
      await checkAuth();
    };

    if (!authChecked) {
      initialCheck();
    }
  }, [checkAuth, authChecked]);

  const handleAuthOperation = async (operation, ...args) => {
    clearError(); // Nutze globalen clearError
    setLoading(true);

    try {
      const result = await operation(...args);
      if (result.user) {
        setUser(result.user);
      }
      return { success: true, data: result };
    } catch (err) {
      handleError(err);
      return { success: false, error: err };
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    const result = await handleAuthOperation(AuthService.login, email, password);
    if (result.success) {
      await checkAuth(true);
    }
    return result;
  };

  const register = async (userData) => {
    const result = await handleAuthOperation(AuthService.register, userData);
    if (result.success) {
      await new Promise(resolve => setTimeout(resolve, 500));
      await checkAuth(true);

      if (!user && result.data?.email && userData.password) {
        return login(result.data.email, userData.password);
      }
    }
    return result;
  };

  const logout = async () => {
    setLoading(true);
    clearError();
    try {
      await AuthService.logout();
      setUser(null);
      return { success: true };
    } catch (err) {
      handleError(err);
      return { success: false, error: err };
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    error, // Error aus dem globalen ErrorContext
    isAuthenticated: !!user,
    authChecked,
    login,
    register,
    logout,
    requestPasswordReset: (email) => handleAuthOperation(AuthService.requestPasswordReset, email),
    resetPassword: (token, newPassword) => handleAuthOperation(AuthService.resetPassword, token, newPassword),
    checkAuth,
    clearError
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;