import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../config/api';
import { useChat } from './useChat';
import { useTextChat } from './useTextChat';
import { useVoiceChat } from './useVoiceChat';
import { useBaseChatApi } from './useBaseChatApi';
import { useErrorHandler } from './useErrorHandler';

export const useChatApi = () => {
  const navigate = useNavigate();
  const { chatId } = useParams();
  const { error, handleError, clearError } = useErrorHandler();
  const {
    setMessages,
    setActiveChat,
    setIsLoading,
    addMessage,
    updateMessage,
    removeMessage
  } = useChat();
  const { sendTextMessage } = useTextChat();
  const { sendVoiceMessage } = useVoiceChat();
  const { createNewChat } = useBaseChatApi();

  const sendMessage = useCallback(async (
    content,
    forceNewChat = false,
    isVoice = false,
    tempBlobUrl = null
  ) => {
    let currentChatId = chatId;

    try {
      if (!currentChatId || forceNewChat) {
        currentChatId = await createNewChat();
        navigate(`/chat/${currentChatId}`);
      }

      if (isVoice) {
        const result = await sendVoiceMessage(content, currentChatId, tempBlobUrl);
        return result;
      } else {
        return await sendTextMessage(content, currentChatId);
      }
    } catch (error) {
      console.error('[useChatApi] Error sending message', { error, tempBlobUrl });
      if (tempBlobUrl) {
        URL.revokeObjectURL(tempBlobUrl);
      }
      handleError(error);
      throw error;
    }
  }, [chatId, navigate, sendTextMessage, sendVoiceMessage, createNewChat, handleError]);

  const loadChatMessages = useCallback(async (chatId) => {
    if (!chatId) return;

    setIsLoading(true);
    clearError();

    try {
      const { data } = await api.get(`/chat/${chatId}/messages`);

      if (Array.isArray(data)) {
        setMessages(data);
        setActiveChat(chatId);
      } else {
        const error = new Error('Ungültige Chat-Daten empfangen');
        error.response = {
          status: 500,
          data: {
            error: {
              code: 'CHAT_005',
              message: 'Chat-Daten konnten nicht geladen werden'
            }
          }
        };
        throw error;
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        try {
          const newChatId = await createNewChat();
          navigate(`/chat/${newChatId}`);
        } catch (createError) {
          handleError(createError);
        }
      } else {
        handleError(error);
        setMessages([]);
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    setMessages,
    setActiveChat,
    navigate,
    createNewChat,
    handleError,
    clearError
  ]);

  return {
    sendMessage,
    loadChatMessages,
    createNewChat,
    error,
    clearError
  };
};