import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, PlusCircle, Settings, LogOut, BookOpen } from 'lucide-react';
import { ResponseTypeToggle } from './ResponseTypeToggle';
import { useChat } from '../../hooks/useChat';
import { useNavigate } from 'react-router-dom';
import useStore from "../../services/userStore";
import { useAuth } from '../../context/AuthContext';
import UserMenu from './UserMenu';

// Verbesserte MentorAvatar Komponente
const MentorAvatar = ({ selectedAvatar, mentorName }) => (
  <div className="flex items-center gap-2 sm:gap-4">
    <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full overflow-hidden shadow-md ring-2 ring-blue-100">
      <img
        src={`/Avatars/mentor${selectedAvatar}.png`}
        alt={`Mentor ${mentorName}`}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="flex flex-col">
      <span className="text-sm sm:text-lg font-semibold text-gray-800 leading-tight">{mentorName}</span>
      <span className="text-xs sm:text-sm text-gray-500 leading-tight">Dein ReflectAI Coach</span>
    </div>
  </div>
);

// Hauptkomponente
export const ChatHeader = ({
    onReflectionToggle
  }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const store = useStore();
    const {
      responseType,
      isSidebarVisible,
      setIsSidebarVisible,
      setResponseType,
    } = useChat();

  const clearUserData = useStore(state => state.clearUserData);

  useEffect(() => {
    const cachedData = localStorage.getItem('mentor_prefs_cache');
    if (cachedData) {
      try {
        const data = JSON.parse(cachedData);
        if (
          data.mentorName !== store.mentorName ||
          data.selectedAvatar !== store.selectedAvatar ||
          data.selectedVoice !== store.selectedVoice ||
          data.addressType !== store.addressType
        ) {
          store.setMentorPreferences({
            mentorName: data.mentorName,
            selectedAvatar: data.selectedAvatar,
            selectedVoice: data.selectedVoice,
            addressType: data.addressType
          });
        }
      } catch (error) {
      }
    }
  }, []);

  const handleLogout = async () => {
  try {
    await logout();
    clearUserData();
    localStorage.clear();
    window.location.href = '/';
  } catch (error) {
    console.error('Logout error:', error);
    alert(error.message || 'Abmelden fehlgeschlagen. Bitte versuchen Sie es erneut.');
  }
};

  const handleNewChat = () => {
    navigate('/chat');
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  return (
    <header className="flex items-center justify-between px-2 sm:px-6 py-2 sm:py-4 bg-white border-b border-gray-100 shadow-sm backdrop-blur-sm bg-white/90">
      {/* Linke Seite */}
      <div className="flex items-center gap-2 sm:gap-6">
        <div className="flex items-center gap-1 sm:gap-3">
          <button
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            className="p-1.5 sm:p-2 hover:bg-gray-100 rounded-lg"
            aria-label="Toggle Sidebar"
          >
            <Menu className="w-5 h-5 sm:w-6 sm:h-6 text-gray-600" />
          </button>

          <button
            onClick={handleNewChat}
            className="p-1.5 sm:p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
            aria-label="New Chat"
          >
            <PlusCircle className="w-5 h-5 sm:w-6 sm:h-6" />
          </button>
        </div>

        <div className="h-8 w-px bg-gray-200 hidden sm:block" />
        <MentorAvatar selectedAvatar={store.selectedAvatar} mentorName={store.mentorName} />
      </div>

      {/* Rechte Seite */}
      <div className="flex items-center gap-2 sm:gap-6">
        <button
          onClick={onReflectionToggle}
          className="p-1.5 sm:p-2 hover:bg-gray-100 rounded-lg text-blue-600"
          aria-label="Toggle Reflection"
        >
          <BookOpen className="w-5 h-5 sm:w-6 sm:h-6" />
        </button>

        <div className="hidden sm:block">
          <ResponseTypeToggle value={responseType} onChange={setResponseType} />
        </div>

        <UserMenu onSettingsClick={handleSettings} onLogout={handleLogout} />
      </div>
    </header>
  );
};

UserMenu.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired
};

MentorAvatar.propTypes = {
  selectedAvatar: PropTypes.number.isRequired,
  mentorName: PropTypes.string.isRequired
};

ChatHeader.propTypes = {
  selectedAvatar: PropTypes.number,
  mentorName: PropTypes.string,
  initials: PropTypes.string,
  responseType: PropTypes.oneOf(['text', 'audio']),
  isSidebarVisible: PropTypes.bool,
  setIsSidebarVisible: PropTypes.func,
  setResponseType: PropTypes.func,
  clearUserData: PropTypes.func,
  isReflectionOpen: PropTypes.bool,
  onReflectionToggle: PropTypes.func,
};