import React, { useState, useEffect } from 'react';
import { MessageCircle, Link, BookOpen, X, Save, Clock, Edit2, Tag, Search } from 'lucide-react';
import SessionDurationInput from "./DurationInput";
import { FormField } from '../ErrorComponents';

// Simple Alert Component
const SimpleAlert = ({ type, message }) => {
  const styles = {
    success: 'bg-green-50 border-green-500 text-green-700',
    error: 'bg-red-50 border-red-500 text-red-700',
    info: 'bg-blue-50 border-blue-500 text-blue-700'
  };

  return (
    <div className={`p-3 mb-4 border-l-4 ${styles[type]}`}>
      <p className="text-sm">{message}</p>
    </div>
  );
};

const EnhancedReflectionForm = ({
  reflectionData,
  setReflectionData,
  handleSave,
  isLoading,
  editingEntry,
  onCancelEdit,
  linkedChats,
  handleRemoveChat,
  handleLinkChat,
  loadAvailableChats
}) => {
  const [showChatSelector, setShowChatSelector] = useState(false);

  // Filtere bereits verknüpfte Chats aus der Liste der verfügbaren Chats
  const availableChats = linkedChats.filter(chat =>
    !reflectionData.linkedChats?.some(linked => linked.id === chat.id)
  );

  // Neuer Handler für das Öffnen des Chat Selectors
  const handleShowChatSelector = async () => {
    await loadAvailableChats(); // Lade die Chats neu
    setShowChatSelector(true);
  };

  // Effekt zum Laden der Chats wenn die Komponente mounted
  useEffect(() => {
    loadAvailableChats();
  }, [loadAvailableChats]);

  // Optional: Neuladen wenn das Fenster Fokus erhält
  useEffect(() => {
    const handleFocus = () => {
      loadAvailableChats();
    };

    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [loadAvailableChats]);

  return (
    <div className="p-4 space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">Thema/Situation</label>
        <input
          type="text"
          className="w-full p-2 border rounded-md focus:border-blue-400 focus:outline-none"
          placeholder="Worüber hast du geredet?"
          value={reflectionData.topic}
          onChange={(e) => setReflectionData({...reflectionData, topic: e.target.value})}
          disabled={isLoading}
        />
      </div>

      <div className="flex items-center justify-between">
        <label className="block text-sm font-medium">Verknüpfte Chats</label>
        {availableChats.length > 0 && (
          <button
            onClick={() => setShowChatSelector(!showChatSelector)}
            className="text-blue-600 hover:text-blue-700 text-sm flex items-center space-x-1"
          >
            <Link className="w-4 h-4" />
            <span>Chat verknüpfen</span>
          </button>
        )}
      </div>

      {showChatSelector && (
        <div className="bg-gray-50 p-3 rounded-md border">
          <div className="space-y-2 max-h-40 overflow-y-auto">
            {availableChats.length > 0 ? (
              availableChats.map(chat => (
                <div
                  key={chat.id}
                  className="flex items-center justify-between bg-white p-2 rounded hover:bg-gray-50"
                >
                  <div className="flex items-center space-x-2">
                    <MessageCircle className="w-4 h-4 text-gray-400" />
                    <span className="text-sm">{chat.title}</span>
                  </div>
                  <button
                    onClick={() => {
                      handleLinkChat(chat.id);
                      setShowChatSelector(false);
                    }}
                    className="text-blue-600 hover:text-blue-700 text-sm"
                  >
                    Verknüpfen
                  </button>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-2">
                Keine weiteren Chats verfügbar
              </div>
            )}
          </div>
        </div>
      )}

      {reflectionData.linkedChats?.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {reflectionData.linkedChats.map(chat => (
            <div
              key={chat.id}
              className="flex items-center space-x-1 bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-sm"
            >
              <MessageCircle className="w-3 h-3" />
              <span>{chat.title}</span>
              <button
                onClick={() => handleRemoveChat(chat.id)}
                className="hover:text-blue-800"
              >
                <X className="w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      )}

      <SessionDurationInput
        value={reflectionData.duration}
        onChange={(duration) => setReflectionData({...reflectionData, duration})}
        disabled={isLoading}
      />

      <div>
        <label className="block text-sm font-medium mb-1">
          Notizen
        </label>
        <textarea
          className="w-full p-2 border rounded-md focus:border-blue-400 focus:outline-none min-h-[200px] resize-none"
          placeholder="Möchtest du etwas festhalten"
          value={reflectionData.notes}
          onChange={(e) => setReflectionData({...reflectionData, notes: e.target.value})}
          disabled={isLoading}
        />
      </div>

      <div className="flex space-x-2">
        <button
          onClick={handleSave}
          disabled={isLoading || !reflectionData.topic.trim()}
          className={`flex-1 px-4 py-2 rounded-md flex items-center justify-center space-x-2
            ${(!isLoading && reflectionData.topic.trim())
              ? 'bg-blue-600 hover:bg-blue-700 text-white' 
              : 'bg-blue-200 text-blue-400 cursor-not-allowed'}`}
        >
          <Save className="w-4 h-4" />
          <span>{editingEntry ? 'Aktualisieren' : 'Speichern'}</span>
        </button>

        {editingEntry && (
          <button
            onClick={onCancelEdit}
            className="px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-600"
            disabled={isLoading}
          >
            Abbrechen
          </button>
        )}
      </div>
    </div>
  );
};

const EnhancedReflectionHistory = ({ entries, isLoading, onEdit, onDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEntries = entries.filter(entry => {
    const searchLower = searchTerm.toLowerCase();
    return !searchTerm ||
      entry.topic?.toLowerCase().includes(searchLower) ||
      entry.notes?.toLowerCase().includes(searchLower) ||
      entry.linkedChats?.some(chat =>
        chat.title.toLowerCase().includes(searchLower)
      );
  });

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return '';
      return date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (e) {
      return '';
    }
  };

  return (
    <div className="p-3 space-y-3">
      <div className="relative">
        <Search className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          className="w-full pl-8 pr-3 py-1.5 border rounded-md focus:border-blue-400 focus:outline-none text-sm"
          placeholder="Suche nach Thema, Inhalt oder Chat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="space-y-2">
        {filteredEntries.length === 0 ? (
          <div className="text-center text-gray-500 py-2 text-sm">
            Keine Einträge gefunden
          </div>
        ) : (
          filteredEntries.map((entry) => (
            <div key={entry.id} className="p-2.5 bg-gray-50 rounded-lg border border-gray-200 group hover:border-gray-300 transition-colors">
              <div className="space-y-1.5">
                {/* Title and Date */}
                <div className="flex justify-between items-center">
                  <h3 className="font-medium text-gray-900">
                    {entry.title}
                  </h3>
                  <div className="text-xs text-gray-500">
                    {formatDate(entry.timestamp)}
                  </div>
                </div>

                {/* Linked Chat */}
                <div className="flex items-center space-x-1.5">
                  <MessageCircle className="w-3.5 h-3.5 text-blue-600" />
                  <span className="text-xs font-medium text-blue-900">
                    {entry.linkedChats?.[0]?.title || "Kein Chat verknüpft"}
                  </span>
                </div>

                {/* Notes */}
                <p className="text-xs text-gray-600 line-clamp-2">
                  {entry.notes}
                </p>

                {/* Actions */}
                <div className="flex justify-end items-center">
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex space-x-1">
                    <button
                      onClick={() => onEdit(entry)}
                      className="p-1 rounded hover:bg-gray-200 text-gray-600 transition-colors"
                      aria-label="Eintrag bearbeiten"
                    >
                      <Edit2 className="w-3.5 h-3.5" />
                    </button>
                    <button
                      onClick={() => onDelete(entry.id)}
                      className="p-1 rounded hover:bg-gray-200 text-gray-600 transition-colors"
                      aria-label="Eintrag löschen"
                    >
                      <X className="w-3.5 h-3.5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export { EnhancedReflectionForm, EnhancedReflectionHistory, SimpleAlert };