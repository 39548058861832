import React, { useState, useEffect } from 'react';
import { AudioPlayer } from '../../AudioPlayer/AudioPlayer';
import { BaseMessage } from './BaseMessage';
import { Loader2 } from 'lucide-react';
import LoadingMessage from './LoadingMessage';
import { api } from '../../../config/api';

export const VoiceMessage = ({ message, selectedAvatar, onMessageLoaded }) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const audioFilename = message.audio_filename || message.audio_filename ||
                       (typeof message.message === 'string' ? undefined : message.audio_filename);

  useEffect(() => {
    let isMounted = true;

    const loadAudio = async () => {
      try {
        const response = await api.get(`/chat/messages/audio/${audioFilename}`, {
          responseType: 'blob'
        });

        if (isMounted) {
          const currentBlobUrl = URL.createObjectURL(response.data);

          setAudioBlob(currentBlobUrl);
          onMessageLoaded?.(message.id, currentBlobUrl);
          setIsLoading(false);
        } else {
          URL.revokeObjectURL(URL.createObjectURL(response.data));
        }
      } catch (err) {
        console.error('[VoiceMessage] Error loading audio', {
          messageId: message.id,
          error: err
        });
        if (isMounted) {
          setError(err);
          setIsLoading(false);
        }
      }
    };

    loadAudio();

    return () => {
      isMounted = false;
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [message, audioFilename, onMessageLoaded]);

  // Show loading spinner for user messages
  if (isLoading && message.role === 'user') {
    return (
      <BaseMessage
        message={message}
        selectedAvatar={selectedAvatar}
      >
        <div className="flex justify-end p-3">
          <Loader2 className="w-6 h-6 animate-spin text-white" />
        </div>
      </BaseMessage>
    );
  }

  // Show regular loading message for assistant messages
  if (isLoading && message.role === 'assistant') {
    return <LoadingMessage selectedAvatar={selectedAvatar} />;
  }

  if (error) {
    return (
      <BaseMessage
        message={message}
        selectedAvatar={selectedAvatar}
      >
        <div className="p-3">
          <div className="flex items-center space-x-3 mb-0">
            <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100">
              <span className="text-red-500">!</span>
            </div>
            <div className="flex-1 text-sm text-red-500">
              Fehler beim Laden der Audio-Datei
            </div>
          </div>
        </div>
      </BaseMessage>
    );
  }

  if (!audioBlob) {
    console.log(`[VoiceMessage] No audio blob available for message ${message.id}, rendering loading`, {
      message,
      audioFilename
    });
    return <LoadingMessage selectedAvatar={selectedAvatar} />;
  }

  return (
    <BaseMessage
      message={{ ...message, messageWidth: 'w-[32rem]' }}
      selectedAvatar={selectedAvatar}
    >
      <div className="w-full">
        <AudioPlayer
          audioFilename={audioBlob}
          isUser={message.role === 'user'}
          isLoading={false}
        />
      </div>
    </BaseMessage>
  );
};