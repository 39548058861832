// SettingScreen.js
import React, { useEffect } from 'react';
import { User, Mic, MessageSquare } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { VoiceSelector } from '../components/MentorPreferences/VoiceSelector';
import { AddressTypeSelector } from '../components/MentorPreferences/AddressTypeSelector';
import { AvatarSelector } from '../components/MentorPreferences/AvatarSelector';
import useStore from '../services/userStore';

import {
  PageLayout,
  SettingsCard,
  LoadingSpinner,
  StatusMessage,
  ActionButton,
  FormInput,
  Header
} from '../Layout/PreferenceLayout';

const Settings = () => {
  const navigate = useNavigate();
  const {
    mentorName,
    selectedAvatar,
    selectedVoice,
    addressType,
    isLoading,
    error,
    isAuthenticated,
    isSaved,
    setMentorPreferences,
    saveToServer,
    syncWithServer,
    loadFromLocalStorage,
    clearError
  } = useStore();

  useEffect(() => {
  const initializeSettings = async () => {
    try {
      const syncSuccess = await syncWithServer();
      if (!syncSuccess) {
        loadFromLocalStorage();
      }
    } catch (err) {
      loadFromLocalStorage();
    }
  };

  initializeSettings();
  return () => clearError();
}, [syncWithServer, loadFromLocalStorage, clearError]);

  const handleNavigateBack = () => {
    clearError();
    navigate(-1);
  };

  const handleSave = async () => {
    try {
      clearError();
      const success = await saveToServer();
      if (success) {
        await syncWithServer();
        setTimeout(() => {
          navigate(-1);
        }, 500);
      }
    } catch (err) {
    }
  };

  const handlePreferenceChange = (updates) => {
    clearError();
    setMentorPreferences({
      mentorName,
      selectedAvatar,
      selectedVoice,
      addressType,
      ...updates
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout>
      <Header
        title="Einstellungen"
        showBackButton
        onBack={handleNavigateBack}
      />

      {error && (
        <StatusMessage
          type="error"
          message={error instanceof Error ? error.message : String(error)}
        />
      )}

      {!isAuthenticated && (
        <StatusMessage
          type="warning"
          message="Du bist nicht eingeloggt. Die Einstellungen werden nur lokal gespeichert."
        />
      )}

      <div className="space-y-6">
        <SettingsCard
          icon={User}
          title="Coach Auswahl"
          description="Wähle deine*n Coach*in aus"
        >
          <div className="space-y-2">
            <AvatarSelector
              selectedAvatar={selectedAvatar}
              onAvatarSelect={(avatar) => handlePreferenceChange({ selectedAvatar: avatar })}
            />
            <div className="space-y-6">
              <FormInput
                label="Name des Coaches"
                value={mentorName || ''}
                onChange={(e) => handlePreferenceChange({ mentorName: e.target.value })}
                placeholder="Wie lautet sein / ihr Name?"
              />
            </div>
          </div>
        </SettingsCard>

        <SettingsCard
          icon={Mic}
          title="Stimme Einstellungen"
          description="Wähle die Stimme deines Coaches"
        >
          <VoiceSelector
            selectedVoice={selectedVoice}
            onVoiceSelect={(voice) => handlePreferenceChange({ selectedVoice: voice })}
            addressType={addressType}
          />
        </SettingsCard>

        <SettingsCard
          icon={MessageSquare}
          title="Anrede Einstellungen"
          description="Wie soll dich dein Coach ansprechen?"
        >
          <AddressTypeSelector
            addressType={addressType}
            onAddressTypeChange={(type) => handlePreferenceChange({ addressType: type })}
          />
        </SettingsCard>
      </div>

      <div className="flex justify-end mt-8">
        <ActionButton
          onClick={handleSave}
          variant={isSaved ? 'success' : 'primary'}
        >
          {isSaved ? 'Gespeichert!' : 'Speichern'}
        </ActionButton>
      </div>
    </PageLayout>
  );
};

export default Settings;