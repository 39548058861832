import React from 'react';
import { Menu, PlusCircle, Settings, LogOut } from 'lucide-react';
import { ResponseTypeToggle } from './ResponseTypeToggle';
import { useChat } from '../../hooks/useChat';
import useStore from "../../services/userStore";

const UserMenu = ({ onSettingsClick, onLogout }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const { initials } = useStore();
  const { responseType, setResponseType } = useChat();

  const handleLogoutClick = async () => {
  if (isLoggingOut) return;
  try {
    setIsLoggingOut(true);
    await onLogout();
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    setIsLoggingOut(false);
    setIsOpen(false);
  }
};

  return (
    <div className="relative">
      <button
        onClick={() => !isLoggingOut && setIsOpen(!isOpen)}
        className="flex items-center justify-center w-12 h-12 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 rounded-full text-white text-lg font-medium transition-all duration-300 transform hover:scale-105 shadow-md"
        disabled={isLoggingOut}
      >
        {initials}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-3 w-56 bg-white rounded-xl shadow-xl py-2 z-10 ring-1 ring-black/5 transform transition-all duration-200">
          <div className="block sm:hidden px-4 py-3">
            <ResponseTypeToggle value={responseType} onChange={setResponseType} />
          </div>
          <div className="block sm:hidden h-px bg-gray-200 my-1" />
          <button
            onClick={() => {
              onSettingsClick();
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-3 text-sm text-gray-700 hover:bg-blue-50 transition-colors duration-200 gap-3"
            disabled={isLoggingOut}
          >
            <Settings className="w-4 h-4" />
            Einstellungen
          </button>
          <div className="h-px bg-gray-200 my-1" />
          <button
            onClick={handleLogoutClick}
            className="flex items-center w-full px-4 py-3 text-sm text-red-600 hover:bg-red-50 transition-colors duration-200 gap-3"
            disabled={isLoggingOut}
          >
            <LogOut className="w-4 h-4" />
            {isLoggingOut ? 'Abmelden...' : 'Abmelden'}
          </button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;