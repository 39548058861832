// audioManager.js
class AudioManager {
  constructor() {
    this.currentlyPlaying = null;
    this.subscribers = new Set();
  }

  setCurrentlyPlaying(audioPlayer) {
    if (this.currentlyPlaying && this.currentlyPlaying !== audioPlayer) {
      // Benachrichtige den vorherigen Player, dass er stoppen soll
      this.notifySubscribers(this.currentlyPlaying);
    }
    this.currentlyPlaying = audioPlayer;
  }

  stopCurrent() {
    if (this.currentlyPlaying) {
      this.notifySubscribers(this.currentlyPlaying);
      this.currentlyPlaying = null;
    }
  }

  subscribe(callback) {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  notifySubscribers(exceptPlayer) {
    this.subscribers.forEach(callback => callback(exceptPlayer));
  }
}

export const audioManager = new AudioManager();