import { useState } from 'react';
import { validateForm } from '../utils/formValidation';

export const useFormValidation = (formType, initialState = {}) => {
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const validateFormData = () => {
    const { isValid, error } = validateForm(formData, formType);
    if (!isValid) {
      setError(error);
    }
    return isValid;
  };

  return {
    formData,
    setFormData,
    error,
    setError,
    isLoading,
    setIsLoading,
    handleChange,
    validateFormData
  };
};
