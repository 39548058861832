// components/Registration/PasswordInput.jsx
import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator';

export const PasswordInput = ({
  id,
  name,
  value,
  onChange,
  disabled,
  showStrengthIndicator = false,
  showRequirements = false,
  className = '',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={`w-full p-3 pr-10 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent ${className}`}
          {...props}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          disabled={disabled}
          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 disabled:text-gray-300"
        >
          {showPassword ? (
            <EyeOff className="h-5 w-5" />
          ) : (
            <Eye className="h-5 w-5" />
          )}
        </button>
      </div>

      {showStrengthIndicator && value && (
        <PasswordStrengthIndicator password={value} />
      )}

      {showRequirements && (
        <ul className="text-sm text-gray-600 space-y-1 mt-2">
          <li className={value.length >= 8 ? 'text-green-600' : ''}>
            • Mindestens 8 Zeichen
          </li>
          <li className={/[A-Z]/.test(value) ? 'text-green-600' : ''}>
            • Mindestens ein Großbuchstabe
          </li>
          <li className={/[a-z]/.test(value) ? 'text-green-600' : ''}>
            • Mindestens ein Kleinbuchstabe
          </li>
          <li className={/[0-9]/.test(value) ? 'text-green-600' : ''}>
            • Mindestens eine Zahl
          </li>
        </ul>
      )}
    </div>
  );
};