import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Mic, Send, Square, Trash2, Play, Pause } from 'lucide-react';

const useAudioProgress = (audioBlob, isPlaying) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);
  const blobUrlRef = useRef(null);

  const cleanup = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
      audioRef.current.load();
      audioRef.current = null;
    }
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
      blobUrlRef.current = null;
    }
    setCurrentTime(0);
    setError(null);
  }, []);

  const initializeAudio = useCallback(() => {
    if (!audioBlob) return;

    cleanup();

    try {
      const newBlobUrl = URL.createObjectURL(audioBlob);
      blobUrlRef.current = newBlobUrl;
      const audio = new Audio();

      const handleTimeUpdate = () => {
        if (audio && Number.isFinite(audio.currentTime)) {
          setCurrentTime(Math.round(audio.currentTime));
        }
      };

      const handleEnded = () => {
        setCurrentTime(0);
        audio.currentTime = 0;
      };

      const handleError = (e) => {
        setError(e);
        cleanup();
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('error', handleError);

      audio.src = newBlobUrl;
      audio.load();
      audioRef.current = audio;

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('error', handleError);
      };
    } catch (err) {
      setError(err);
      cleanup();
    }
  }, [audioBlob, cleanup]);

  useEffect(() => {
    const cleanupFn = initializeAudio();
    return () => {
      if (cleanupFn) cleanupFn();
      cleanup();
    };
  }, [audioBlob, initializeAudio, cleanup]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    try {
      if (isPlaying) {
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            setError(error);
          });
        }
      } else {
        audio.pause();
      }
    } catch (err) {
      setError(err);
    }

    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [isPlaying]);

  return {
    currentTime,
    error
  };
};

export const MessageInput = ({ value, onChange, onRecord, disabled, isRecording }) => {
  const textAreaRef = useRef(null);
  const formRef = useRef(null);

  const adjustHeight = useCallback(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (value.trim() && !disabled) {
        formRef.current?.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }
  }, [value, disabled]);

  useEffect(() => {
    if (textAreaRef.current) {
      formRef.current = textAreaRef.current.closest('form');
    }
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [value, adjustHeight]);

  return (
    <div className="flex items-center justify-center w-full p-4">
      <div className="flex items-center space-x-3 max-w-4xl w-full">
        <div className="flex-1 flex items-end bg-gray-100 rounded-2xl px-3">
          <div className="flex-1 flex items-end">
            <textarea
              ref={textAreaRef}
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              rows={1}
              placeholder="Schreibe eine Nachricht..."
              className="w-full p-3 bg-transparent border-none focus:outline-none text-gray-800 placeholder-gray-500 resize-none min-h-[44px] max-h-[200px] overflow-y-auto"
              style={{
                lineHeight: '1.5',
              }}
            />
          </div>
          {value.trim() ? (
            <button
              type="submit"
              disabled={disabled}
              className="p-3 text-blue-500 hover:bg-blue-50 rounded-full transition-colors disabled:text-gray-400 disabled:hover:bg-transparent self-end"
            >
              <Send className="w-6 h-6" />
            </button>
          ) : (
            <button
              type="button"
              onClick={onRecord}
              disabled={disabled}
              className={`p-3 transition-colors rounded-full self-end ${
                isRecording 
                  ? 'text-red-500 hover:bg-red-50' 
                  : 'text-blue-500 hover:bg-blue-50'
              } disabled:text-gray-400 disabled:hover:bg-transparent`}
            >
              {isRecording ? <Square className="w-6 h-6" /> : <Mic className="w-6 h-6" />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const RecordingState = ({ time, onStop }) => (
  <div className="flex items-center justify-center w-full p-4">
    <div className="flex items-center space-x-3 max-w-4xl w-full">
      <div className="flex-1 flex items-center gap-3 p-3 bg-red-50 rounded-2xl">
        <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse" />
        <span className="text-sm text-red-700">{time}</span>
      </div>
      <button
        type="button"
        onClick={onStop}
        className="p-3 text-red-500 hover:bg-red-50 rounded-full transition-colors"
      >
        <Square className="w-5 h-5" />
      </button>
    </div>
  </div>
);

export const RecordedPreview = ({
  onPlayPause,
  isPlaying,
  onDelete,
  onSend,
  disabled,
  audioBlob,
  formatTime
}) => {
  const { currentTime, error } = useAudioProgress(audioBlob, isPlaying);
  const displayCurrentTime = Number.isFinite(currentTime) ? currentTime : 0;

  if (error) {
    return (
      <div className="flex items-center justify-center w-full p-4">
        <div className="flex items-center space-x-3 max-w-4xl w-full">
          <div className="flex-1 flex items-center gap-3 p-3 bg-red-50 rounded-2xl">
            <span className="text-sm text-red-700">Failed to load audio</span>
          </div>
          <button
            type="button"
            onClick={onDelete}
            className="p-3 text-red-500 hover:bg-red-50 rounded-full"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full p-4">
      <div className="flex items-center space-x-3 max-w-4xl w-full">
        <div className="flex-1 flex items-center gap-3 p-3 bg-blue-50 rounded-2xl">
          <button
            type="button"
            onClick={onPlayPause}
            className="shrink-0 p-1 text-blue-500 hover:bg-blue-100 rounded-full"
          >
            {isPlaying ? (
              <Pause className="w-5 h-5" />
            ) : (
              <Play className="w-5 h-5" />
            )}
          </button>
          <span className="shrink-0 text-sm text-blue-500 min-w-16">
            {formatTime(displayCurrentTime)}
          </span>
        </div>
        <button
          type="button"
          onClick={onDelete}
          className="p-3 text-red-500 hover:bg-red-50 rounded-full"
        >
          <Trash2 className="w-5 h-5" />
        </button>
        <button
          type="submit"
          disabled={disabled}
          className="p-3 text-blue-500 hover:bg-blue-50 rounded-full disabled:text-gray-400 disabled:hover:bg-transparent"
        >
          <Send className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};