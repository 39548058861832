import { useCallback, useState } from 'react';
import { VoiceMessage } from './VoiceMessage';
import { TextMessage } from './TextMessage';

export const ChatMessage = ({ message, selectedAvatar }) => {
  const [loadedAudioBlobs, setLoadedAudioBlobs] = useState({});

  const handleAudioLoaded = useCallback((messageId, blobUrl) => {
    setLoadedAudioBlobs(prev => {
      const newBlobs = {
        ...prev,
        [messageId]: blobUrl
      };
      return newBlobs;
    });
  }, []);

  const isVoiceMessage = message.metadata?.isVoiceMessage ||
                        message.audio_filename ||
                        message.type === 'audio';

  if (isVoiceMessage) {
    return (
      <VoiceMessage
        message={message}
        selectedAvatar={selectedAvatar}
        onMessageLoaded={handleAudioLoaded}
      />
    );
  }

  return <TextMessage message={message} selectedAvatar={selectedAvatar} />;
};