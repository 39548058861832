// hooks/useChat.js
import { useContext, useCallback } from 'react';
import { ChatContext, ACTIONS } from '../context/ChatContext';

export const useChat = () => {
  const context = useContext(ChatContext);

   if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  const { state, dispatch } = context;

  // Message Actions
  const setMessages = useCallback((messages) => {
    dispatch({ type: ACTIONS.SET_MESSAGES, payload: messages });
  }, [dispatch]);

  const addMessage = useCallback((message) => {
    dispatch({ type: ACTIONS.ADD_MESSAGE, payload: message });
  }, [dispatch]);

  const updateMessage = useCallback((messageId, updates) => {
    dispatch({
      type: ACTIONS.UPDATE_MESSAGE,
      payload: { id: messageId, ...updates }
    });
  }, [dispatch]);

  const removeMessage = useCallback((messageId) => {
    dispatch({ type: ACTIONS.REMOVE_MESSAGE, payload: messageId });
  }, [dispatch]);

  // Loading State
  const setIsLoading = useCallback((isLoading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: isLoading });
  }, [dispatch]);

  const clearMessages = useCallback(() => {
    dispatch({ type: ACTIONS.CLEAR_MESSAGES });
  }, [dispatch]);

  // Error Handling
  const setError = useCallback((error) => {
    dispatch({ type: ACTIONS.SET_ERROR, payload: error });
  }, [dispatch]);

  // Chat Management
  const setActiveChat = useCallback((chatId) => {
    dispatch({ type: ACTIONS.SET_ACTIVE_CHAT, payload: chatId });
  }, [dispatch]);

  const clearChat = useCallback(() => {
    dispatch({ type: ACTIONS.CLEAR_CHAT });
  }, [dispatch]);

  // Message Helpers
  const getMessageById = useCallback((messageId) => {
    return state.messages.find(msg => msg.id === messageId);
  }, [state.messages]);

  const getLastMessage = useCallback(() => {
    return state.messages[state.messages.length - 1];
  }, [state.messages]);

  const getPendingMessages = useCallback(() => {
    return state.messages.filter(msg => msg.status === 'pending');
  }, [state.messages]);

  // Chat Status Helpers
  const hasUnreadMessages = useCallback(() => {
    return state.messages.some(msg =>
      msg.role === 'assistant' && !msg.isRead
    );
  }, [state.messages]);

  const getChatSummary = useCallback(() => {
    const lastMsg = getLastMessage();
    return {
      messageCount: state.messages.length,
      lastMessage: lastMsg,
      hasUnread: hasUnreadMessages(),
      isActive: Boolean(state.activeChat)
    };
  }, [state.messages, state.activeChat, getLastMessage, hasUnreadMessages]);

  // Optimistic Updates
  const handleOptimisticUpdate = useCallback(async (action, rollbackData) => {
    try {
      // Perform optimistic update
      dispatch(action);
      // Return any cleanup function if needed
      return () => {
        if (rollbackData) {
          dispatch(rollbackData);
        }
      };
    } catch (error) {
      // Rollback on error
      if (rollbackData) {
        dispatch(rollbackData);
      }
      throw error;
    }
  }, [dispatch]);

  const setResponseType = useCallback((type) => {
    dispatch({ type: ACTIONS.SET_RESPONSE_TYPE, payload: type });
  }, [dispatch]);

  const setIsSidebarVisible = useCallback((isVisible) => {
    dispatch({ type: ACTIONS.SET_SIDEBAR_VISIBLE, payload: isVisible });
  }, [dispatch]);

  return {
    // State
    messages: state.messages,
    isLoading: state.isLoading,
    error: state.error,
    activeChat: state.activeChat,
    selectedAvatar: state.selectedAvatar,
    responseType: state.responseType,
    isSidebarVisible: state.isSidebarVisible,

    // Actions
    setMessages,
    addMessage,
    updateMessage,
    removeMessage,
    setIsLoading,
    setError,
    setActiveChat,
    clearChat,
    setResponseType,
    setIsSidebarVisible,
    clearMessages,

    // Helpers
    getMessageById,
    getLastMessage,
    getPendingMessages,
    hasUnreadMessages,
    getChatSummary,
    handleOptimisticUpdate
  };
};