import { useCallback } from 'react';
import { api } from '../config/api';
import { useChat } from './useChat';
import { useBaseChatApi } from './useBaseChatApi';

export const useVoiceChat = () => {
  const {
    addMessage,
    setIsLoading,
    responseType,
  } = useChat();

  const { processApiError, getMentorPreferences, clearError } = useBaseChatApi();

  const sendVoiceMessage = useCallback(async (formData, chatId) => {
    setIsLoading(true);
    clearError();

    try {
      formData.append('response_type', responseType);
      formData.append('chat_id', chatId);
      formData.append('mentor_preferences', JSON.stringify(getMentorPreferences()));

      const { data: messageData } = await api.post('/chat/messages/voice', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // User Message erstellen
      const userMessage = {
        role: 'user',
        content: messageData.user_message_text || '',
        audio_filename: messageData.user_message_audio_filename,
        id: messageData.user_message_id,
        created_at: new Date().toISOString(),
        metadata: { isVoiceMessage: true },
        status: 'received',
      };

      // Assistant Message hinzufügen
      const assistantMessage = {
        role: 'assistant',
        content: messageData.message,
        audio_filename: messageData.audio_filename,
        id: messageData.id,
        created_at: messageData.created_at,
        metadata: messageData.metadata || {},
        status: 'received',
      };

      await Promise.all([
        addMessage(userMessage),
        addMessage(assistantMessage)
      ]);

      return messageData;

    } catch (error) {
      console.error('[useVoiceChat] Error sending voice message', error);
      processApiError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [
    responseType,
    addMessage,
    setIsLoading,
    processApiError,
    getMentorPreferences,
    clearError
  ]);

  return { sendVoiceMessage };
};