import React, { useEffect } from 'react';
import { BookOpen, X, Save, Clock, Edit2, Tag, Search, Filter, Download } from 'lucide-react';
import { EnhancedReflectionForm, EnhancedReflectionHistory } from './ReflectionComponents';
import { useReflection } from './useReflection';
import { useToast } from '../ErrorComponents';

const ReflectionHeader = ({ onClose }) => (
  <div className="px-6 py-4 bg-white border-b border-gray-100 shadow-sm backdrop-blur-sm bg-white/90">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <BookOpen className="w-6 h-6 text-blue-600" />
        <span className="text-lg font-semibold text-gray-800">Notizen</span>
      </div>
      <button
        onClick={onClose}
        className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
        aria-label="Close Reflection"
      >
        <X className="w-6 h-6 text-gray-600" />
      </button>
    </div>
  </div>
);

const ReflectionTabs = ({ activeTab, onTabChange }) => (
  <div className="flex border-b">
    <button
      className={`flex-1 py-2 text-sm font-medium transition-colors ${
        activeTab === 'current' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'
      }`}
      onClick={() => onTabChange('current')}
    >
      Aktueller Eintrag
    </button>
    <button
      className={`flex-1 py-2 text-sm font-medium transition-colors ${
        activeTab === 'history' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'
      }`}
      onClick={() => onTabChange('history')}
    >
      Historie
    </button>
  </div>
);

const Alert = ({ type, message }) => {
  const styles = {
    success: 'bg-green-50 border-green-500 text-green-700',
    error: 'bg-red-50 border-red-500 text-red-700',
    info: 'bg-blue-50 border-blue-500 text-blue-700'
  };

  return (
    <div className={`p-4 border-l-4 ${styles[type]}`}>
      <p className="text-sm">{message}</p>
    </div>
  );
};

const Stats = ({ data = {} }) => {
  const {
    totalEntries = 0,
    averageDuration = 0,
    topCategories = [],
    topTags = []
  } = data;

  return null; // Placeholder return for now
};

const ReflectionSidebar = ({ isOpen, onClose }) => {
  const {
    activeTab,
    setActiveTab,
    reflectionData,
    setReflectionData,
    savedEntries,
    isLoading,
    editingEntry,
    setEditingEntry,
    handleSave,
    handleEdit,
    handleDelete,
    resetForm,
    handleRemoveChat,
    handleLinkChat,
    availableChats,
    loadAvailableChats,
  } = useReflection();

  const { ToastContainer } = useToast();

  return (
    <>
      <ToastContainer />
      <div className={`fixed right-0 top-0 h-full w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <div className="px-6 py-4 bg-white border-b border-gray-100 shadow-sm backdrop-blur-sm bg-white/90">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <BookOpen className="w-6 h-6 text-blue-600" />
              <span className="text-lg font-semibold text-gray-800">Notizen</span>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              aria-label="Close Reflection"
            >
              <X className="w-6 h-6 text-gray-600" />
            </button>
          </div>
        </div>

        <div className="flex border-b">
          <button
            className={`flex-1 py-2 text-sm font-medium transition-colors ${
              activeTab === 'current' 
                ? 'text-blue-600 border-b-2 border-blue-600' 
                : 'text-gray-600 hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('current')}
          >
            Aktueller Eintrag
          </button>
          <button
            className={`flex-1 py-2 text-sm font-medium transition-colors ${
              activeTab === 'history' 
                ? 'text-blue-600 border-b-2 border-blue-600' 
                : 'text-gray-600 hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('history')}
          >
            Historie
          </button>
        </div>

        <div className="overflow-y-auto h-[calc(100vh-12rem)]">
          {activeTab === 'current' ? (
            <EnhancedReflectionForm
              reflectionData={reflectionData}
              setReflectionData={setReflectionData}
              handleSave={handleSave}
              isLoading={isLoading}
              editingEntry={editingEntry}
              onCancelEdit={() => {
                resetForm();
                setEditingEntry(null);
              }}
              linkedChats={availableChats}
              handleRemoveChat={handleRemoveChat}
              handleLinkChat={handleLinkChat}
              loadAvailableChats={loadAvailableChats}
            />
          ) : (
            <EnhancedReflectionHistory
              entries={savedEntries}
              isLoading={isLoading}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReflectionSidebar;