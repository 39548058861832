import React from 'react';
import { useFormValidation } from '../../hooks/useFormValidation';
import { PasswordInput } from './PasswordInput';
import LoadingSubmitButton from '../LoadingSubmitButton';

export const RegistrationForm = ({ onSubmit, isLoading }) => {
  const {
    formData,
    handleChange,
    validateFormData,
  } = useFormValidation('registration', {
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    password: '',
    confirmPassword: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFormData()) return;
    onSubmit(formData);
  };

  return (
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
            >
              Vorname
            </label>
            <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                disabled={isLoading}
                placeholder="Max"
                className="w-full p-3 border rounded-lg bg-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            />
          </div>

          <div className="space-y-2">
            <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
            >
              Nachname
            </label>
            <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                disabled={isLoading}
                placeholder="Mustermann"
                className="w-full p-3 border rounded-lg bg-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label
              htmlFor="gender"
              className="block text-sm font-medium text-gray-700"
          >
            Geschlecht
          </label>
          <div className="relative">
            <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                disabled={isLoading}
                className="w-full p-3 border rounded-lg bg-white appearance-none pl-4 pr-10 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
            >
              <option value="">Bitte wählen</option>
              <option value="male">Männlich</option>
              <option value="female">Weiblich</option>
              <option value="diverse">Divers</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-400">
              <svg
                  className="h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
              >
                <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
          >
            E-Mail
          </label>
          <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isLoading}
              placeholder="max.mustermann@example.com"
              className="w-full p-3 border rounded-lg bg-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>
        <div className="space-y-2">
          <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
          >
            Passwort
          </label>
          <PasswordInput
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              disabled={isLoading}
              showStrengthIndicator={true}
              showRequirements={true}
          />
        </div>

        <div className="space-y-2">
          <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
          >
            Passwort bestätigen
          </label>
          <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              disabled={isLoading}
          />
          {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword && (
              <p className="text-sm text-red-600">
                Die Passwörter stimmen nicht überein
              </p>
          )}
        </div>

        <LoadingSubmitButton isLoading={isLoading}>
          Konto erstellen
        </LoadingSubmitButton>
      </form>
  );
};