import { useState, useEffect, useCallback } from 'react';
import { api } from '../../config/api';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useToast } from '../ErrorComponents';

// Custom hook for managing API loading and error states
const useApiState = (initialState = null) => {
  const [data, setData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const startLoading = useCallback(() => setIsLoading(true), []);
  const stopLoading = useCallback(() => setIsLoading(false), []);
  const clearError = useCallback(() => setError(null), []);

  const withLoading = useCallback(async (operation) => {
    try {
      startLoading();
      clearError();
      return await operation();
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      stopLoading();
    }
  }, []);

  return {
    data,
    setData,
    isLoading,
    error,
    withLoading,
    clearError
  };
};

// API service functions
const reflectionApi = {
  async getChats() {
    const { data } = await api.get('/chat/chats');
    return data;
  },

  async getNotes(currentChatId) {
    const endpoint = currentChatId ? `/notes/chat/${currentChatId}` : '/notes/';
    const { data } = await api.get(endpoint);
    return data;
  },

  async createNote(noteData) {
    return await api.post('/notes/', noteData);
  },

  async updateNote(id, noteData) {
    return await api.put(`/notes/${id}`, noteData);
  },

  async deleteNote(id) {
    return await api.delete(`/notes/${id}`);
  },

  async addChatMapping(noteId, chatId) {
    return await api.post('/notes/mapping', { note_id: noteId, chat_id: chatId });
  },

  async removeChatMapping(chatId, noteId) {
    return await api.delete(`/notes/mapping/${chatId}/${noteId}`);
  }
};

const initialReflectionState = {
  topic: '',
  title: '',
  content: '',
  notes: '',
  duration: '15',
  timestamp: new Date().toISOString(),
  linkedChats: []
};

export const useReflection = (currentChatId = null) => {
  const { handleError } = useErrorHandler();
  const { addToast } = useToast();
  const [activeTab, setActiveTab] = useState('current');
  const [editingEntry, setEditingEntry] = useState(null);

  // Use custom API state hooks for better state management
  const {
    data: reflectionData,
    setData: setReflectionData,
    withLoading: withReflectionLoading
  } = useApiState(initialReflectionState);

  const {
    data: savedEntries,
    setData: setSavedEntries,
    withLoading: withEntriesLoading
  } = useApiState([]);

  const {
    data: availableChats,
    setData: setAvailableChats,
    withLoading: withChatsLoading
  } = useApiState([]);

  // Memoized data transformations
  const transformNoteData = useCallback((note) => ({
    id: note.id,
    topic: note.title,
    title: note.title,
    notes: note.content,
    content: note.content,
    timestamp: note.created_at,
    chat_ids: note.chats?.map(chat => chat.chat_id) || [],
    linkedChats: note.chats?.map(chat => ({
      id: chat.chat_id,
      title: chat.chat_title
    })) || []
  }), []);

  const transformChatData = useCallback((chat) => ({
    id: chat.id,
    title: chat.title || `Chat ${chat.id}`,
    lastMessage: chat.last_message,
    messagesCount: chat.messages_count,
    updatedAt: chat.updated_at
  }), []);

  // Load available chats
  const loadAvailableChats = useCallback(async () => {
    try {
      const data = await withChatsLoading(async () => {
        const chats = await reflectionApi.getChats();
        const chatsWithMessages = chats.filter(chat => chat.messages_count > 0);
        return chatsWithMessages.map(transformChatData);
      });
      setAvailableChats(data);
      return data;
    } catch (err) {
      handleError(err);
      addToast('Fehler beim Laden der verfügbaren Chats', 'error');
      return [];
    }
  }, [withChatsLoading, handleError, addToast]);

  // Load entries
  const loadEntries = useCallback(async () => {
    try {
      const notes = await withEntriesLoading(async () => {
        const data = await reflectionApi.getNotes(currentChatId);
        return data.map(transformNoteData);
      });
      setSavedEntries(notes);
    } catch (err) {
      handleError(err);
      addToast('Fehler beim Laden der Einträge', 'error');
    }
  }, [currentChatId, withEntriesLoading, handleError, addToast]);

  // Save handler
  const handleSave = useCallback(async () => {
    if (!reflectionData.topic?.trim() && !reflectionData.title?.trim()) {
      addToast('Bitte geben Sie einen Titel an', 'warning');
      return;
    }

    try {
      await withReflectionLoading(async () => {
        const chatIds = [
          ...new Set([
            ...(reflectionData.linkedChats?.map(chat => chat.id) || []),
            ...(currentChatId ? [currentChatId] : [])
          ])
        ];

        const noteData = {
          title: (reflectionData.title || reflectionData.topic)?.trim(),
          content: (reflectionData.content || reflectionData.notes)?.trim(),
          chat_ids: chatIds
        };

        if (editingEntry) {
          await reflectionApi.updateNote(editingEntry.id, noteData);
          addToast('Eintrag erfolgreich aktualisiert', 'success');
        } else {
          await reflectionApi.createNote(noteData);
          addToast('Eintrag erfolgreich gespeichert', 'success');
        }

        resetForm();
        setActiveTab('history');
        await loadEntries();
      });
    } catch (err) {
      handleError(err);
      addToast('Fehler beim Speichern des Eintrags', 'error');
    }
  }, [reflectionData, editingEntry, currentChatId, withReflectionLoading]);

  // Delete handler
  const handleDelete = useCallback(async (id) => {
    try {
      await withEntriesLoading(async () => {
        await reflectionApi.deleteNote(id);
        setSavedEntries(prev => prev.filter(entry => entry.id !== id));
        addToast('Eintrag erfolgreich gelöscht', 'success');

        if (editingEntry?.id === id) {
          setEditingEntry(null);
          resetForm();
        }
      });
    } catch (err) {
      handleError(err);
      addToast('Fehler beim Löschen des Eintrags', 'error');
    }
  }, [withEntriesLoading, editingEntry, handleError, addToast]);

  // Chat linking handlers
  const handleLinkChat = useCallback(async (chatId) => {
  const chatToLink = availableChats.find(chat => chat.id === chatId);
  if (!chatToLink) return;

  setReflectionData(prev => ({
    ...prev,
    linkedChats: [...(prev.linkedChats || []), chatToLink]
  }));

  if (editingEntry) {
    try {
      const noteData = {
        title: reflectionData.title || reflectionData.topic,
        content: reflectionData.content || reflectionData.notes,
        chat_ids: [...reflectionData.linkedChats.map(chat => chat.id), chatId]
      };

      await reflectionApi.updateNote(editingEntry.id, noteData);
      await loadEntries();
      addToast('Chat erfolgreich verknüpft', 'success');
    } catch (err) {
      handleError(err);
      addToast('Fehler beim Verknüpfen des Chats', 'error');
    }
  }
}, [availableChats, editingEntry, reflectionData, handleError, addToast]);

  const handleRemoveChat = useCallback(async (chatId) => {
  // Update UI optimistically
  setReflectionData(prev => ({
    ...prev,
    linkedChats: prev.linkedChats.filter(chat => chat.id !== chatId)
  }));

  if (editingEntry) {
    try {
      // Only delete the mapping instead of updating the whole note
      await reflectionApi.removeChatMapping(chatId, editingEntry.id);
      await loadEntries(); // Reload to ensure sync with server
      addToast('Chat-Verknüpfung erfolgreich entfernt', 'success');
    } catch (err) {
      // Revert UI state on error
      setReflectionData(prev => ({
        ...prev,
        linkedChats: [...prev.linkedChats, availableChats.find(chat => chat.id === chatId)].filter(Boolean)
      }));
      handleError(err);
      addToast('Fehler beim Entfernen der Chat-Verknüpfung', 'error');
    }
  }
}, [editingEntry, availableChats, handleError, addToast, loadEntries]);

  // Form handling
  const handleEdit = useCallback((entry) => {
    setReflectionData({
      topic: entry.title || entry.topic,
      title: entry.title || entry.topic,
      notes: entry.content || entry.notes,
      content: entry.content || entry.notes,
      duration: entry.duration || '15',
      timestamp: entry.timestamp || new Date().toISOString(),
      linkedChats: entry.linkedChats || []
    });
    setEditingEntry(entry);
    setActiveTab('current');
  }, []);

  const resetForm = useCallback(() => {
    setReflectionData(initialReflectionState);
    setEditingEntry(null);
  }, []);

  // Effects
  useEffect(() => {
    if (activeTab === 'history') {
      loadEntries();
    }
  }, [activeTab, loadEntries]);

  useEffect(() => {
    loadAvailableChats();
  }, [loadAvailableChats]);

  return {
    activeTab,
    setActiveTab,
    reflectionData,
    setReflectionData,
    savedEntries,
    isLoading: withReflectionLoading.isLoading || withEntriesLoading.isLoading || withChatsLoading.isLoading,
    editingEntry,
    setEditingEntry,
    loadEntries,
    handleSave,
    handleEdit,
    handleDelete,
    resetForm,
    loadAvailableChats,
    availableChats,
    handleLinkChat,
    handleRemoveChat,
  };
};