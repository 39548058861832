import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useAuth } from "../context/AuthContext";
import React from "react";
import useStore from '../services/userStore';
import { RegistrationForm } from '../components/Registration/RegistrationForm';
import Layout from '../Layout/Layout';
import { useErrorHandler } from '../hooks/useErrorHandler';

const RegistrationScreen = () => {
  const navigate = useNavigate();
  const { register, login } = useAuth();
  const setInitials = useStore(state => state.setInitials);
  const { error, handleError, clearError } = useErrorHandler();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    clearError();

    try {
      const registrationData = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        username: `${formData.firstName.trim()} ${formData.lastName.trim()}`,
        email: formData.email.trim(),
        password: formData.password,
        gender: formData.gender
      };

      const registrationResult = await register(registrationData);

      if (registrationResult.success) {
        const loginResult = await login(
          registrationData.email,
          registrationData.password
        );

        if (loginResult.success) {
          const initials = `${formData.firstName[0].toUpperCase()}${formData.lastName[0].toUpperCase()}`;
          setInitials(initials);
          navigate('/mentor-select', { replace: true });
        } else {
          handleError({
            code: 'AUTH_001',
            message: 'Registrierung erfolgreich, aber automatische Anmeldung fehlgeschlagen. Bitte melden Sie sich manuell an.',
            details: { error: loginResult.error }
          });
        }
      } else if (registrationResult.error?.code) {
        handleError(registrationResult.error);
      } else {
        handleError({
          code: 'GEN_001',
          message: registrationResult.error || 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.',
          details: {}
        });
      }
    } catch (err) {
      if (err.response?.data?.error) {
        handleError(err.response.data.error);
      } else {
        handleError({
          code: 'GEN_002',
          message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
          details: {}
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const customFooter = (
    <div className="space-y-4">
      <div className="text-xs text-gray-500 text-center">
        Mit der Registrierung akzeptierst du unsere{' '}
        <a href="#" className="text-teal-600 hover:underline">
          Nutzungsbedingungen
        </a>{' '}
        und{' '}
        <a href="#" className="text-teal-600 hover:underline">
          Datenschutzerklärung
        </a>
        .
      </div>
      <div className="text-center">
        <button
          type="button"
          onClick={() => navigate('/login')}
          className="text-teal-600 hover:text-teal-700 hover:underline text-sm inline-flex items-center transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
        >
          <ArrowLeft className="h-4 w-4 mr-1"/>
          Zurück zum Login
        </button>
      </div>
    </div>
  );

  return (
    <Layout
      title="Kostenloses Konto erstellen"
      subtitle="Registriere dich jetzt und beginne deine Reise zur Selbstentwicklung mit deinem persönlichen KI-Mentor."
      customFooter={customFooter}
      error={error}
    >
      <RegistrationForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Layout>
  );
};

export default RegistrationScreen;