import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useFormValidation } from '../hooks/useFormValidation';
import useStore from '../services/userStore';
import Layout from '../Layout/Layout';

const WelcomeLoginScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, error, clearError } = useAuth();
  const from = location.state?.from?.pathname || '/chat';
  const syncWithServer = useStore(state => state.syncWithServer);
  const setInitials = useStore(state => state.setInitials);

  const {
    formData,
    isLoading,
    setIsLoading,
    handleChange,
    validateFormData
  } = useFormValidation('login', {
    email: '',
    password: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) return;

    setIsLoading(true);
    clearError();

    try {
      const result = await login(formData.email.trim(), formData.password);

      if (result.success) {
        const userInitials = result.data.user?.initials || '';
        setInitials(userInitials);
        await syncWithServer();
        navigate(from, { replace: true });
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterClick = () => {
    clearError();
    navigate('/register');
  };

  const customFooter = (
    <>
      <div className="relative my-6 w-full">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">oder</span>
        </div>
      </div>

      <button
        onClick={handleRegisterClick}
        type="button"
        className="w-full py-3 text-teal-600 rounded-lg font-medium border-2 border-teal-500 hover:bg-teal-50 transition-colors"
        disabled={isLoading}
      >
        Kostenloses Konto erstellen
      </button>

      <div className="mt-6 text-sm text-gray-500 text-center">
        <a
          href="/reset-password"
          onClick={(e) => {
            e.preventDefault();
            clearError();
            navigate('/reset-password');
          }}
          className="hover:text-teal-600 transition-colors"
        >
          Passwort vergessen?
        </a>
      </div>
    </>
  );

  return (
    <Layout
      title="Willkommen zurück!"
      subtitle="Bereit für deine nächste Coaching-Session?"
      showBackToLogin={false}
      customFooter={customFooter}
      error={error}
    >
      <div className="space-y-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div>
              <input
                type="email"
                name="email"
                placeholder="E-Mail-Adresse"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                required
                autoComplete="email"
                disabled={isLoading}
              />
            </div>

            <div>
              <input
                type="password"
                name="password"
                placeholder="Passwort"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                required
                autoComplete="current-password"
                disabled={isLoading}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-3 bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></span>
                Wird verarbeitet...
              </span>
            ) : (
              'Anmelden'
            )}
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default WelcomeLoginScreen;