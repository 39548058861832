import React from 'react';

const Card = ({ 
  title, 
  subtitle,
  headerContent,
  footerContent,
  error,
  success,
  children,
  className = ''
}) => {
  return (
    <div className="w-full max-w-md">
      <div className={`bg-white rounded-2xl shadow-xl p-8 ${className}`}>
        {/* Header Section */}
        {(title || subtitle || headerContent) && (
          <div className="text-center space-y-2 mb-6">
            {headerContent}
            {title && (
              <h1 className="text-2xl font-bold text-gray-800">
                {title}
              </h1>
            )}
            {subtitle && (
              <p className="text-sm text-gray-600">
                {subtitle}
              </p>
            )}
          </div>
        )}

        {/* Content Section */}
        <div className="space-y-6">
          {error && (
            <div className="p-4 bg-red-50 text-red-800 border border-red-200 rounded-lg flex items-start">
              {error}
            </div>
          )}

          {success && (
            <div className="p-4 bg-green-50 text-green-800 border border-green-200 rounded-lg flex items-start">
              {success}
            </div>
          )}

          {children}
        </div>

        {/* Footer Section */}
        {footerContent && (
          <div className="mt-6">
            {footerContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;