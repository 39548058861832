// layouts/PageLayout.js
import React from 'react';
import {ArrowLeft} from "lucide-react";

export const PageLayout = ({ children }) => (
  <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
    <div className="max-w-3xl mx-auto px-4 py-8">
      {children}
    </div>
  </div>
);

export const SettingsCard = ({ icon: Icon, title, description, children }) => (
  <div className="bg-white rounded-xl shadow-sm overflow-hidden transition-all duration-200 hover:shadow-md">
    <div className="p-6 border-b border-gray-100">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Icon className="w-5 h-5 text-blue-600" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </div>
    <div className="p-6">{children}</div>
  </div>
);

export const LoadingSpinner = () => (
  <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500"></div>
  </div>
);

export const StatusMessage = ({ type, message }) => {
  const styles = {
    error: {
      bg: 'bg-red-50',
      border: 'border-red-200',
      text: 'text-red-600'
    },
    warning: {
      bg: 'bg-amber-50',
      border: 'border-amber-200',
      text: 'text-amber-700'
    },
    success: {
      bg: 'bg-green-50',
      border: 'border-green-200',
      text: 'text-green-600'
    }
  };

  const style = styles[type] || styles.error;

  return (
    <div className={`mb-6 p-4 ${style.bg} border ${style.border} rounded-xl animate-fade-in`}>
      <p className={style.text}>{message}</p>
    </div>
  );
};

export const ActionButton = ({ onClick, disabled, isLoading, children, variant = 'primary' }) => {
  const variants = {
    primary: 'bg-blue-500 hover:bg-blue-600',
    success: 'bg-green-500 hover:bg-green-600'
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`px-6 py-2.5 rounded-lg text-white font-medium transition-all duration-300 transform 
                hover:translate-y-[-1px] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:translate-y-0 
                ${variants[variant]}`}
    >
      {isLoading ? 'Wird geladen...' : children}
    </button>
  );
};

export const FormInput = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  errorMessage
}) => (
  <div className="space-y-2">
    {label && (
      <label className="block text-sm font-medium text-gray-700">{label}</label>
    )}
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`w-full px-4 py-2.5 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 
                focus:border-transparent transition-shadow duration-200 
                ${error ? 'border-red-500' : 'border-gray-200'}`}
    />
    {error && errorMessage && (
      <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
    )}
  </div>
);

export const Header = ({
  title,
  subtitle,
  showBackButton = false,
  onBack
}) => (
  <div className={`${showBackButton ? 'flex items-center gap-4' : 'text-center'} mb-8`}>
    {showBackButton && (
      <button
        onClick={onBack}
        className="p-2 hover:bg-white/50 rounded-lg transition-colors duration-200"
      >
        <ArrowLeft className="w-6 h-6 text-gray-600" />
      </button>
    )}
    <div>
      <h1 className={`text-2xl font-bold ${showBackButton ? 'text-gray-800' : 'text-teal-500'}`}>
        {title}
      </h1>
      {subtitle && <p className="text-gray-600">{subtitle}</p>}
    </div>
  </div>
);