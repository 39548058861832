import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import {ErrorProvider} from "./context/ErrorContext";
import ProtectedRoute from './ProtectedRoute';
import WelcomeScreen from './screens/LoginScreen';
import MentorSelect from './screens/MentorSelectScreen';
import ChatScreen from './screens/ChatScreen';
import RegistrationScreen from "./screens/RegistrationScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import { ChatProvider } from './context/ChatContext';
import SettingScreen from './screens/SettingScreen';
import PasswortSetScreen from "./screens/PasswortSetScreen";


const App = () => {
  return (
    <Router>
         <ErrorProvider>
      <AuthProvider>
        <ChatProvider>
          <Routes>
            {/* Public routes - accessible when logged out */}
            <Route
              path="/"
              element={
                <ProtectedRoute requireAuth={false}>
                  <WelcomeScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedRoute requireAuth={false}>
                  <RegistrationScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <ProtectedRoute requireAuth={false}>
                  <PasswordResetScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/set-password/:token"
              element={
                <ProtectedRoute requireAuth={false}>
                  <PasswortSetScreen />
                </ProtectedRoute>
              }
            />
            {/* Protected routes - require authentication */}
            <Route
              path="/mentor-select"
              element={
                <ProtectedRoute requireAuth={true}>
                  <MentorSelect />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute requireAuth={true}>
                  <ChatScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:chatId"
              element={
                <ProtectedRoute requireAuth={true}>
                  <ChatScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute requireAuth={true}>
                  <SettingScreen />
                </ProtectedRoute>
              }
            />

            {/* Catch all route - redirect to home */}
            <Route
              path="*"
              element={
                <ProtectedRoute requireAuth={false}>
                  <WelcomeScreen />
                </ProtectedRoute>
              }
            />
          </Routes>
        </ChatProvider>
      </AuthProvider>
    </ErrorProvider>
    </Router>
  );
};

export default App;