export class AppError extends Error {
  constructor(code, message, details = {}, status = 500) {
    super(message);
    this.name = 'AppError';
    this.code = code;
    this.status = status;
    this.details = details;
  }
}

export class AuthError extends AppError {
  constructor(code, message, details = {}, status = 401) {
    super(code, message, details, status);
    this.name = 'AuthError';
  }
}

export class ValidationError extends AppError {
  constructor(code, message, details = {}, status = 400) {
    super(code, message, details, status);
    this.name = 'ValidationError';
  }
}