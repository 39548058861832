import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useFormValidation } from '../hooks/useFormValidation';
import { AuthService } from '../config/auth';
import { useErrorHandler } from '../hooks/useErrorHandler';
import Layout from '../Layout/Layout';
import LoadingSubmitButton from '../components/LoadingSubmitButton';

const PasswordResetScreen = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState('');
  const { error, handleError, clearError } = useErrorHandler();
  const {
    formData,
    isLoading,
    setIsLoading,
    handleChange,
    validateFormData
  } = useFormValidation('passwordReset', { email: '' });

  const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateFormData()) return;

  setIsLoading(true);
  clearError();

  try {
    const response = await AuthService.requestPasswordReset(formData.email.trim());
    setSuccess('Falls ein Account mit dieser E-Mail existiert, wurde ein Reset-Link verschickt.');
    setTimeout(() => {
      navigate('/');
    }, 2000);
  } catch (err) {
    if (err.response?.status === 429) {
      handleError({
        code: 'AUTH_005',
        message: 'Zu viele Versuche. Bitte warte einige Minuten und versuche es dann erneut.',
        details: {}
      });
    } else {
      handleError({
        code: 'GEN_003',
        message: 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.',
        details: {}
      });
    }
  } finally {
    setIsLoading(false);
  }
};

  return (
    <Layout
      title="Passwort zurücksetzen"
      subtitle="Gib deine E-Mail-Adresse ein und wir senden dir einen Link zum Zurücksetzen deines Passworts."
      error={error}
      success={success}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            E-Mail-Adresse
          </label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="name@beispiel.de"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent
              transition-all duration-200 ease-in-out
              disabled:opacity-50 disabled:cursor-not-allowed
              invalid:border-red-500 invalid:ring-red-500"
            required
            autoComplete="email"
            disabled={isLoading || success}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={error ? 'email-error' : undefined}
          />
        </div>

        <LoadingSubmitButton
          isLoading={isLoading}
          disabled={isLoading || success}
          loadingText="Wird verarbeitet..."
        >
          Link zum Zurücksetzen senden
        </LoadingSubmitButton>
      </form>
    </Layout>
  );
};

export default PasswordResetScreen;