import { useCallback } from 'react';
import { api } from '../config/api';
import { useChat } from './useChat';
import { ACTIONS } from "../context/ChatContext";
import { useBaseChatApi } from './useBaseChatApi';

export const useTextChat = () => {
  const {
    addMessage,
    updateMessage,
    removeMessage,
    setIsLoading,
    responseType,
    handleOptimisticUpdate
  } = useChat();

  const { processApiError, getMentorPreferences, clearError } = useBaseChatApi();

  const sendTextMessage = useCallback(async (content, chatId) => {
    const tempMessageId = crypto.randomUUID();
    setIsLoading(true);
    clearError();

    try {
      const userMessage = {
        role: 'user',
        content,
        created_at: new Date().toISOString(),
        id: tempMessageId
      };

      await handleOptimisticUpdate(
        { type: ACTIONS.ADD_MESSAGE, payload: userMessage },
        { type: ACTIONS.REMOVE_MESSAGE, payload: tempMessageId }
      );

      const { data: messageData } = await api.post('/chat/messages', {
        message: content,
        chat_id: chatId,
        response_type: responseType,
        mentor_preferences: getMentorPreferences(),
      });

      updateMessage(tempMessageId, {
        id: messageData.user_message_id,
        status: 'sent'
      });

      addMessage({
        role: 'assistant',
        content: messageData.message,
        id: messageData.id,
        created_at: messageData.created_at,
        metadata: messageData.metadata || {},
        status: 'received',
        audio_filename: messageData.audio_filename
      });

      return messageData;
    } catch (error) {
      removeMessage(tempMessageId);
      processApiError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [
    responseType,
    addMessage,
    updateMessage,
    removeMessage,
    setIsLoading,
    handleOptimisticUpdate,
    processApiError,
    getMentorPreferences,
    clearError
  ]);

  return { sendTextMessage };
};