import React from 'react';
import { Play, Pause } from 'lucide-react';


export const AudioPlayerUserUI = ({ isPlaying, onTogglePlayback }) => {
  return (
    <div className="p-3">
      <div className="flex items-center space-x-3">
        <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-blue-400">
          <button
            onClick={onTogglePlayback}
            className="w-full h-full flex items-center justify-center rounded-full transition-colors text-white hover:bg-blue-300"
            aria-label={isPlaying ? 'Pause' : 'Play'}
          >
            {isPlaying ? (
              <Pause className="w-4 h-4" />
            ) : (
              <Play className="w-4 h-4" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export const AudioPlayerAssistantUI = ({
  isPlaying,
  isLoading,
  error,
  duration,
  currentTime,
  isUser,
  onTogglePlayback
}) => {
  const backgroundColor = isUser ? 'bg-blue-400' : 'bg-blue-500';
  const hoverColor = isUser ? 'hover:bg-blue-300' : 'hover:bg-blue-600';
  const textColor = isUser ? 'text-blue-100' : 'text-gray-500';

  if (error) {
    return (
      <div className="p-3">
        <div className="flex items-center space-x-3 mb-0">
          <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100">
            <span className="text-red-500">!</span>
          </div>
          <div className="flex-1 text-sm text-red-500">
            Fehler beim Laden der Audio-Datei
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3">
      <div className="flex items-center space-x-3 mb-0">
        <div
          className={`flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full
            ${backgroundColor}`}
        >
          {isLoading ? (
            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
          ) : (
            <button
              onClick={onTogglePlayback}
              className={`w-full h-full flex items-center justify-center rounded-full transition-colors text-white ${hoverColor}`}
              aria-label={isPlaying ? 'Pause' : 'Play'}
            >
              {isPlaying ? (
                <Pause className="w-4 h-4" />
              ) : (
                <Play className="w-4 h-4" />
              )}
            </button>
          )}
        </div>

        <div className="flex-1 h-1 bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full ${backgroundColor} transition-all duration-100`}
            style={{ width: `${duration ? (currentTime / duration) * 100 : 0}%` }}
          />
        </div>
      </div>

      <div className="pl-11 text-xs">
        <span className={textColor}>
          {formatTime(duration)}
        </span>
      </div>
    </div>
  );
};

const formatTime = (seconds) => {
  if (!seconds || !isFinite(seconds)) return '0:00';
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

export const AudioPlayerUI = ({
  isPlaying,
  isLoading,
  error,
  duration,
  currentTime,
  isUser,
  onTogglePlayback
}) => {
  if (isUser) {
    return (
      <AudioPlayerUserUI
        isPlaying={isPlaying}
        onTogglePlayback={onTogglePlayback}
      />
    );
  }

  return (
    <AudioPlayerAssistantUI
      isPlaying={isPlaying}
      isLoading={isLoading}
      error={error}
      duration={duration}
      currentTime={currentTime}
      onTogglePlayback={onTogglePlayback}
    />
  );
};

export default AudioPlayerUI;