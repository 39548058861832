import React from 'react';

export const AddressTypeSelector = ({ addressType, onAddressTypeChange }) => {
  return (
    <div className="flex space-x-4">
      <button
        onClick={() => onAddressTypeChange('du')}
        className={`flex-1 py-2 px-4 rounded-lg border ${
          addressType === 'du'
            ? 'border-teal-500'
            : 'border-gray-200 hover:bg-gray-50'
        }`}
      >
        Duzen
      </button>
      <button
        onClick={() => onAddressTypeChange('sie')}
        className={`flex-1 py-2 px-4 rounded-lg border ${
          addressType === 'sie'
            ? 'border-teal-500'
            : 'border-gray-200 hover:bg-gray-50'
        }`}
      >
        Siezen
      </button>
    </div>
  );
};