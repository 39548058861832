// contexts/ErrorContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import { mapErrorToMessage } from '../utils/errors';

const ErrorContext = createContext(null);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = useCallback((error) => {

    // Direkt die Details aus dem Error-Objekt extrahieren, wenn vorhanden
    if (error?.details?.error?.message) {
      const errorInfo = {
        title: 'Fehler',
        message: error.details.error.message,
        details: error.details,
        status: error.status || 500
      };
      setError(errorInfo);
      return errorInfo;
    }

    // Fallback auf die Standard-Fehlerzuordnung
    const errorInfo = mapErrorToMessage(error);

    // Sicherstellen, dass die Nachricht ein String ist
    if (typeof errorInfo.message === 'object' && errorInfo.message !== null) {
      if (errorInfo.message.error) {
        errorInfo.message = errorInfo.message.error;
      } else if (errorInfo.message.message) {
        errorInfo.message = errorInfo.message.message;
      } else {
        errorInfo.message = 'Ein unerwarteter Fehler ist aufgetreten';
      }
    }

    setError(errorInfo);
    return errorInfo;
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <ErrorContext.Provider value={{
      error,
      handleError,
      clearError
    }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorHandler = () => {
  const context = useContext(ErrorContext);
  if (context === null) {
    throw new Error('useErrorHandler must be used within an ErrorProvider');
  }
  return context;
};