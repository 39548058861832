// hooks/useSettings.js
import { useCallback } from 'react';
import useStore from '../services/userStore';

export const useSettings = () => {
  // Get relevant state and methods from userStore
  const store = useStore();

  // Create settings object from store state
  const settings = {
    selectedAvatar: store.selectedAvatar || 1,
    mentorName: store.mentorName || ''
  };

  const updateSettings = useCallback((newSettings) => {
    if (newSettings) {
      // Create updated data object
      const updateData = {
        firstName: store.firstName,
        lastName: store.lastName,
        mentorName: newSettings.mentorName || store.mentorName
      };

      // Use userStore's setUserData method to update
      store.setUserData(
        updateData.firstName,
        updateData.lastName,
        updateData.mentorName
      );
    }
  }, [store]);

  const resetSettings = useCallback(() => {
    // Use userStore's clearUserData method
    store.clearUserData();
  }, [store]);

  return {
    settings,
    updateSettings,
    resetSettings
  };
};

export default useSettings;