// useErrorHandler.js
import { useState, useCallback } from 'react';
import { mapErrorToMessage } from '../utils/errors';

export const useErrorHandler = () => {
  const [error, setError] = useState(null);

  const handleError = useCallback((error) => {
    if (error.details?.error?.message) {
      const errorInfo = {
        title: 'Fehler',
        message: error.details.error.message,
        details: error.details,
        status: error.status || 500
      };
      setError(errorInfo);
      return errorInfo;
    }

    // Fallback auf die Standard-Fehlerzuordnung
    const errorInfo = mapErrorToMessage(error);

    // Sicherstellen, dass die Nachricht ein String ist
    if (typeof errorInfo.message === 'object' && errorInfo.message !== null) {
      // Versuche zuerst message.error zu extrahieren
      if (errorInfo.message.error) {
        errorInfo.message = errorInfo.message.error;
      }
      // Dann versuche message.message zu extrahieren
      else if (errorInfo.message.message) {
        errorInfo.message = errorInfo.message.message;
      }
      // Fallback auf eine generische Fehlermeldung
      else {
        errorInfo.message = 'Ein unerwarteter Fehler ist aufgetreten';
      }
    }

    setError(errorInfo);
    return errorInfo;
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    error,
    handleError,
    clearError
  };
};