// context/ChatContext.js
import React, { createContext, useReducer, useCallback, useRef } from 'react';

export const ChatContext = createContext(null);

export const ACTIONS = {
  SET_MESSAGES: 'SET_MESSAGES',
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_ACTIVE_CHAT: 'SET_ACTIVE_CHAT',
  CLEAR_CHAT: 'CLEAR_CHAT',
  SET_RESPONSE_TYPE: 'SET_RESPONSE_TYPE',
  SET_SIDEBAR_VISIBLE: 'SET_SIDEBAR_VISIBLE',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES'
};

const initialState = {
  messages: [],
  isLoading: false,
  error: null,
  activeChat: null,
  selectedAvatar: 1,
  responseType: 'text',
  isSidebarVisible: false,
};

const chatReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
        error: null
      };

    case ACTIONS.ADD_MESSAGE: {
      // Prüfe ob die Nachricht bereits existiert
      const messageExists = state.messages.some(msg => msg.id === action.payload.id);
      if (messageExists) {
        return state;
      }
      return {
        ...state,
        messages: [...state.messages, action.payload],
        error: null
      };
    }

    case ACTIONS.UPDATE_MESSAGE: {
      const messageExists = state.messages.some(msg => msg.id === action.payload.id);
      if (!messageExists) {
        return state;
      }
      return {
        ...state,
        messages: state.messages.map(msg =>
          msg.id === action.payload.id ? { ...msg, ...action.payload } : msg
        )
      };
    }

    case ACTIONS.REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(msg => msg.id !== action.payload)
      };

    case ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case ACTIONS.SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChat: action.payload,
        error: null
      };

    case ACTIONS.CLEAR_CHAT:
      return {
        ...state,
        messages: [],
        activeChat: null,
        error: null
      };

    case ACTIONS.SET_RESPONSE_TYPE:
      return {
        ...state,
        responseType: action.payload
      };

    case ACTIONS.SET_SIDEBAR_VISIBLE:
      return {
        ...state,
        isSidebarVisible: action.payload
      };

    case ACTIONS.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
        error: null,
        isLoading: false
      };

    default:
      return state;
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const activeMessageUpdate = useRef(null);

  // Wrapper für dispatch, der Updates koordiniert
  const dispatchAction = useCallback((action) => {
    if (action.type === ACTIONS.UPDATE_MESSAGE) {
      // Speichere die aktuelle Message-ID die gerade aktualisiert wird
      activeMessageUpdate.current = action.payload.id;

      // Setze nach kurzer Zeit zurück
      setTimeout(() => {
        activeMessageUpdate.current = null;
      }, 100);
    }

    if (action.type === ACTIONS.SET_MESSAGES && activeMessageUpdate.current) {
      // Ignoriere SET_MESSAGES während eines Message-Updates
      return;
    }

    dispatch(action);
  }, []);

  const value = {
    state,
    dispatch: dispatchAction
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};