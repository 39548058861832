import { api } from './api';
import { AuthError } from '../utils/errors';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

export class AuthService {
  static async login(email, password) {
    try {
      const response = await api.post('/auth/login', { email, password });

      return response.data;
    } catch (error) {
      throw AuthService.handleError(error);
    }
  }

  static async getCurrentUser() {
    try {
      const response = await api.get('/auth/me');
      return response.data;
    } catch (error) {
      throw  AuthService.handleError(error);
    }
  }

  static async logout() {
  try {
    const response = await api.post('/auth/logout');
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error instanceof AuthError) {
      throw error;
    }
    throw new AuthError(
      'AUTH_009',
      'Verbindungsfehler beim Logout',
      error,
      error.response?.status || 500
    );
  }
}

  static async register(userData) {
    try {
      const response = await api.post('/auth/register', userData);
      return response.data;
    } catch (error) {
      throw  AuthService.handleError(error);
    }
  }

  static async requestPasswordReset(email) {
  try {
    const response = await api.post('/auth/reset-password', { email });
    return response.data;
  } catch (error) {
    // Entferne die spezifische 404-Behandlung
    if (error.response?.status === 429) {
      throw new AuthError(
        'API_003',
        'Zu viele Versuche. Bitte warte einige Minuten und versuche es dann erneut.',
        {},
        429
      );
    }
    throw AuthService.handleError(error);
  }
}

  static async setNewPassword(token, password) {
    try {
      const response = await api.post(`/auth/reset-password/${token}`, { password });
      if (response.status !== 200) {
        throw new AuthError(
          'AUTH_006',
          response.data.error || 'Fehler beim Zurücksetzen des Passworts',
          {},
          response.status
        );
      }
      return response;
    } catch (error) {
      throw  AuthService.handleError(error);
    }
  }

  static handleError(error) {

    if (error instanceof AuthError) {
      throw error;
    }

    if (error.response) {
      const status = error.response.status;
      const message = error.response.data.error || 'Ein Fehler ist aufgetreten';

      switch (status) {
        case 400:
          throw new AuthError('AUTH_007', 'Ungültige Anfrage', error.response.data, status);
        case 401:
            const authError = new AuthError(
              'AUTH_001',
              error.response.data.error?.message || 'Nicht autorisiert',
              error.response.data.error?.details || {},
              status
            );
            throw authError;
        case 403:
          throw new AuthError('AUTH_003', 'Zugriff verweigert', error.response.data, status);
        case 404:
          throw new AuthError('AUTH_005', message, error.response.data, status);
        case 429:
          throw new AuthError('API_003', message, error.response.data, status);
        default:
          throw new AuthError('API_001', message, error.response.data, status);
      }
    }

    // Netzwerkfehler
    if (error.message === 'Network Error') {
      throw new AuthError(
        'API_002',
        'Keine Verbindung zum Server möglich',
        {},
        0
      );
    }

    // Fallback für unbekannte Fehler
    throw new AuthError(
      'API_001',
      'Keine Antwort vom Server erhalten',
      {},
      500
    );
  }
}

// Token Refresh Interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Wenn es sich um einen Login-Fehler handelt, direkt durchreichen
    if (originalRequest.url === '/auth/login') {
      return Promise.reject(error);
    }

    // Nur bei 401 und wenn es kein Refresh-Request ist Token erneuern versuchen
    if (
      error.response?.status !== 401 ||
      originalRequest.url === '/auth/refresh' ||
      originalRequest._retry
    ) {
      return Promise.reject(error);
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          return api(originalRequest);
        })
        .catch((err) => {
          throw new AuthError(
            'AUTH_002',
            'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
            {},
            401
          );
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      await api.post('/auth/refresh');
      processQueue(null);
      return api(originalRequest);
    } catch (refreshError) {
      processQueue(refreshError, null);
      throw new AuthError(
        'AUTH_004',
        'Token-Aktualisierung fehlgeschlagen. Bitte melden Sie sich erneut an.',
        {},
        401
      );
    } finally {
      isRefreshing = false;
    }
  }
);