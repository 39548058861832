// AudioPlayer.jsx
import React from 'react';
import { AudioPlayerUI } from './AudioPlayerUI';
import { useAudioPlayer } from './useAudioPlayer';

export const AudioPlayer = ({
  audioFilename,
  isUser = false,
  isLoading = false,
  showUploadingState = false
}) => {
  const {
    isPlaying,
    duration,
    currentTime,
    error,
    isLoading: isLoadingAudio,
    togglePlayback
  } = useAudioPlayer(audioFilename);

  return (
    <AudioPlayerUI
      isPlaying={isPlaying}
      duration={duration}
      currentTime={currentTime}
      error={error}
      isLoading={isLoading || isLoadingAudio}
      isUser={isUser}
      onTogglePlayback={togglePlayback}
      showUploadingState={showUploadingState}
    />
  );
};

export const AudioPlayerUser = ({
  audioFilename,
  isUser = false,
  isLoading = false,
  showUploadingState = false
}) => {
  const {
    isPlaying,
    duration,
    currentTime,
    error,
    isLoading: isLoadingAudio,
    togglePlayback
  } = useAudioPlayer(audioFilename);

  return (
    <AudioPlayerUI
      isPlaying={isPlaying}
      duration={duration}
      currentTime={currentTime}
      error={error}
      isLoading={isLoading || isLoadingAudio}
      isUser={isUser}
      onTogglePlayback={togglePlayback}
      showUploadingState={showUploadingState}
    />
  );
};