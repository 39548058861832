// components/ErrorComponents.jsx
import React from 'react';
import { AlertTriangle, X, Info, CheckCircle, AlertCircle } from 'lucide-react';

export const ERROR_TYPES = {
  error: {
    container: 'bg-red-50 border-red-200',
    icon: 'text-red-500',
    text: 'text-red-800',
    IconComponent: AlertCircle
  },
  warning: {
    container: 'bg-yellow-50 border-yellow-200',
    icon: 'text-yellow-500',
    text: 'text-yellow-800',
    IconComponent: AlertTriangle
  },
  info: {
    container: 'bg-blue-50 border-blue-200',
    icon: 'text-blue-500',
    text: 'text-blue-800',
    IconComponent: Info
  },
  success: {
    container: 'bg-green-50 border-green-200',
    icon: 'text-green-500',
    text: 'text-green-800',
    IconComponent: CheckCircle
  }
};

// Modifizierte getErrorObject Funktion in ErrorComponents.jsx
const getErrorObject = (error) => {
  // Handle string errors
  if (typeof error === 'string') {
    return { type: 'error', message: error };
  }

  // Handle Error instances
  if (error instanceof Error) {
    return {
      type: 'error',
      message: error.message || 'Ein unerwarteter Fehler ist aufgetreten'
    };
  }

  // Handle plain objects
  if (error && typeof error === 'object') {
    return {
      type: error.type || 'error',
      message: error.message || 'Ein unerwarteter Fehler ist aufgetreten'
    };
  }

  // Fallback
  return {
    type: 'error',
    message: 'Ein unerwarteter Fehler ist aufgetreten'
  };
};

export const ErrorAlert = ({ error, onClose }) => {
  if (!error) return null;

  const errorObj = getErrorObject(error);
  const style = ERROR_TYPES[errorObj.type] || ERROR_TYPES.error;
  const { IconComponent } = style;

  return (
    <div className={`p-4 mb-4 border rounded-lg ${style.container}`}>
      <div className="flex justify-between items-start">
        <div className="flex">
          <div className="flex-shrink-0">
            <IconComponent className={`h-5 w-5 ${style.icon}`} />
          </div>
          <div className="ml-3">
            <p className={`text-sm font-medium ${style.text}`}>
              {errorObj.message}
            </p>
            {errorObj.details && Object.keys(errorObj.details).length > 0 && (
              <div className="mt-2">
                {Object.entries(errorObj.details).map(([key, value]) => (
                  <p key={key} className={`text-sm ${style.text}`}>
                    {String(value)}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        {onClose && (
          <button
            onClick={onClose}
            className={`ml-4 ${style.text} hover:opacity-75`}
            aria-label="Fehler schließen"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export const FormFieldError = ({ message }) => {
  if (!message) return null;

  return (
    <p className="mt-1 text-sm text-red-600 flex items-center">
      <AlertCircle className="h-4 w-4 mr-1" />
      {String(message)}
    </p>
  );
};

export const Toast = ({ message, type = 'error', onClose }) => {
  const style = ERROR_TYPES[type] || ERROR_TYPES.error;
  const { IconComponent } = style;

  return (
    <div className="fixed top-4 right-4 z-50 animate-slide-in">
      <div className={`p-4 rounded-lg shadow-lg border ${style.container}`}>
        <div className="flex items-center">
          <IconComponent className={`h-5 w-5 ${style.icon} mr-2`} />
          <p className={`text-sm ${style.text}`}>{String(message)}</p>
          {onClose && (
            <button
              onClick={onClose}
              className={`ml-4 ${style.text} hover:opacity-75`}
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const useToast = () => {
  const [toasts, setToasts] = React.useState([]);

  const addToast = React.useCallback((message, type = 'error') => {
    const id = Date.now();
    setToasts(prev => [...prev, { id, message: String(message), type }]);

    setTimeout(() => {
      setToasts(prev => prev.filter(toast => toast.id !== id));
    }, 5000);
  }, []);

  const removeToast = React.useCallback((id) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  const ToastContainer = React.useCallback(() => (
    <div className="fixed top-4 right-4 z-50 space-y-2">
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>
  ), [toasts, removeToast]);

  return { addToast, ToastContainer };
};

export const FormField = ({
  type = 'text',
  name,
  label,
  value,
  onChange,
  error,
  disabled = false,
  required = false,
  className = '',
  ...props
}) => {
  const inputStyles = `w-full p-3 border rounded-lg transition-colors
    focus:ring-2 focus:ring-teal-500 focus:border-transparent
    ${error ? 'border-red-500 bg-red-50' : 'border-gray-300'}
    ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
    ${className}`;

  return (
    <div className="space-y-1">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <input
        type={type}
        name={name}
        id={name}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        required={required}
        className={inputStyles}
        {...props}
      />
      {error && <FormFieldError message={error} />}
    </div>
  );
};

export default {
  ErrorAlert,
  FormFieldError,
  Toast,
  useToast,
  FormField,
  ERROR_TYPES
};