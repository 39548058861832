// BaseMessage.jsx
import React from 'react';
import useStore from '../../../services/userStore';

export const BaseMessage = ({ message, selectedAvatar, children }) => {
  const { initials } = useStore();
  const isUser = message.role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} max-w-4xl mx-auto group`}>
      {!isUser && (
        <div className="w-8 h-8 mr-3 flex-shrink-0">
          <img
            src={`/Avatars/mentor${selectedAvatar}.png`}
            alt="Mentor"
            className="w-full h-full rounded-full shadow-sm ring-2 ring-white"
          />
        </div>
      )}
      <div className={message.messageWidth || 'max-w-[85%] md:max-w-[75%]'}>
        <div className={`
          shadow-sm hover:shadow-md transition-shadow duration-200
          ${isUser 
            ? 'bg-blue-500 text-white rounded-2xl rounded-tr-lg' 
            : 'bg-white text-gray-800 rounded-2xl rounded-tl-lg'}
        `}>
          {children}
        </div>
      </div>
      {isUser && (
        <div className="w-8 h-8 ml-3 flex-shrink-0">
          <div className="w-full h-full rounded-full shadow-sm ring-2 ring-white flex items-center justify-center bg-blue-500 text-white">
            <span className="text-sm font-medium">{initials}</span>
          </div>
        </div>
      )}
    </div>
  );
};
