// Layout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Card from './Card';
import { ErrorAlert } from "../components/ErrorComponents";
import { useErrorHandler } from '../context/ErrorContext';  // Geändert: Importiere vom Context

const Layout = ({
  title,
  subtitle,
  error: propError,  // Umbenennen zu propError für Klarheit
  success,
  children,
  showBackToLogin = true,
  customFooter,
}) => {
  const navigate = useNavigate();
  const { error: contextError, clearError } = useErrorHandler();

  // Nutze entweder den prop Error oder den Context Error
  const error = propError || contextError;

  const defaultFooter = showBackToLogin && (
    <div className="text-center">
      <button
        type="button"
        onClick={() => navigate('/login')}
        className="text-teal-600 hover:text-teal-700 hover:underline text-sm
          inline-flex items-center transition-colors duration-200
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
      >
        <ArrowLeft className="h-4 w-4 mr-1" />
        Zurück zum Login
      </button>
    </div>
  );

  // Vereinfachte Fehlerverarbeitung - nur title/message anzeigen
  const processedError = error ? {
    type: error.code?.startsWith('AUTH') ? 'warning' : 'error',
    message: error.message || error
  } : null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col items-center justify-center p-4">
      <div className="container mx-auto px-4 py-12 flex flex-col items-center">
        <div
          className="text-5xl font-bold bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent mb-4"
          role="banner"
        >
          ReflectAI
        </div>

        <Card
          title={title}
          subtitle={subtitle}
          success={success}
          footerContent={customFooter || defaultFooter}
        >
          {processedError && (
            <div className="mb-6">
              <ErrorAlert
                error={processedError}
                onClose={clearError}
              />
            </div>
          )}
          {children}
        </Card>
      </div>
    </div>
  );
};

export default Layout;