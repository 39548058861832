import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { Loader2 } from 'lucide-react';

const ProtectedRoute = ({ children, requireAuth = true }) => {
  const { isAuthenticated, loading, authChecked, checkAuth } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && requireAuth) {
      const interval = setInterval(() => {
        checkAuth(true);
      }, 5 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [checkAuth, isAuthenticated, requireAuth]);

  if (loading && !authChecked) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
        <Loader2 className="h-8 w-8 animate-spin text-teal-500" />
        <p className="mt-4 text-sm text-gray-500">Laden...</p>
      </div>
    );
  }

  if (authChecked) {
    // Wenn Auth erforderlich ist und User nicht eingeloggt ist
    if (requireAuth && !isAuthenticated) {
      const returnPath = location.pathname + location.search;
      return <Navigate to="/" state={{ from: returnPath }} replace />;
    }

    // Wenn wir auf einer Public Route sind und der User eingeloggt ist
    if (!requireAuth && isAuthenticated) {
      // Überprüfe, ob wir uns im Registrierungsprozess befinden
      const isRegistrationPath = location.pathname === '/register';
      const isMentorSelectPath = location.pathname === '/mentor-select';

      // Wenn wir uns im Registrierungsprozess befinden, erlaube die Navigation
      if (isRegistrationPath || isMentorSelectPath) {
        return children;
      }

      // Ansonsten standard Weiterleitung
      const returnTo = location.state?.from || '/chat';
      return <Navigate to={returnTo} replace />;
    }
  }

  return children;
};

export default ProtectedRoute;