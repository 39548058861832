export const GERMAN_VOICES = [
  {
    id: 'Vicki',
    name: 'Vicki',
    gender: 'female',
    previewUrl: {
      du: '/audio/vicki_du.mp3',
      sie: '/audio/vicki_sie.mp3'
    }
  },
  {
    id: 'Daniel',
    name: 'Daniel',
    gender: 'male',
    previewUrl: {
      du: '/audio/daniel_du.mp3',
      sie: '/audio/daniel_sie.mp3'
    }
  },
  {
    id: 'Hans',
    name: 'Christian',
    gender: 'male',
    previewUrl: {
      du: '/audio/hans_du.mp3',
      sie: '/audio/hans_sie.mp3'
    }
  },
  {
    id: 'Marlene',
    name: 'Marlene',
    gender: 'female',
    previewUrl: {
      du: '/audio/marlene_du.mp3',
      sie: '/audio/marlene_sie.mp3'
    }
  }
];