// hooks/useChatSidebar.js
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChat } from './useChat';
import { api } from '../config/api';
import { useErrorHandler } from './useErrorHandler';

export const useChatSidebar = () => {
  const [chats, setChats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { setActiveChat } = useChat();
  const { error, handleError, clearError } = useErrorHandler();

  const cacheRef = useRef({
    lastUpdate: 0,
    isInitialized: false
  });

  const CACHE_LIFETIME = 5 * 60 * 1000;

  const shouldRefreshCache = useCallback(() => {
    const now = Date.now();
    return !cacheRef.current.isInitialized ||
           (now - cacheRef.current.lastUpdate) > CACHE_LIFETIME;
  }, []);

  const loadChats = useCallback(async (forceRefresh = false) => {
    try {
      if (!forceRefresh && !shouldRefreshCache()) {
        return;
      }

      setIsLoading(true);
      clearError();

      const { data } = await api.get('/chat/chats');

      if (!Array.isArray(data)) {
        throw new Error('Ungültiges Datenformat von der API');
      }

      const groupedChats = groupChatsByDate(data);
      setChats(groupedChats);

      cacheRef.current = {
        lastUpdate: Date.now(),
        isInitialized: true
      };
    } catch (error) {
      handleError({
        ...error,
        message: error.message || 'Chats konnten nicht geladen werden'
      });
    } finally {
      setIsLoading(false);
    }
  }, [navigate, shouldRefreshCache, handleError, clearError]);

  useEffect(() => {
    loadChats();
    const interval = setInterval(() => loadChats(true), 30000);
    return () => clearInterval(interval);
  }, [loadChats]);

  const handleChatSelect = useCallback(async (chatId) => {
    try {
      setActiveChat(chatId);
      navigate(`/chat/${chatId}`);
    } catch (error) {
      handleError({
        ...error,
        message: 'Chat konnte nicht ausgewählt werden'
      });
      throw error;
    }
  }, [navigate, setActiveChat, handleError]);

  const handleChatDelete = useCallback(async (chatId) => {
    try {
      clearError();
      await api.delete(`/chat/${chatId}`);
      await loadChats(true);
    } catch (error) {
      handleError({
        ...error,
        message: 'Chat konnte nicht gelöscht werden'
      });
      throw error;
    }
  }, [loadChats, handleError, clearError]);

  const handleChatRename = useCallback(async (chatId, newTitle) => {
    try {
      clearError();
      await api.patch(`/chat/${chatId}`, { title: newTitle });
      await loadChats(true);
    } catch (error) {
      handleError({
        ...error,
        message: 'Chat konnte nicht umbenannt werden'
      });
      throw error;
    }
  }, [loadChats, handleError, clearError]);

  return {
    chats,
    isLoading,
    error,
    handleChatSelect,
    handleChatDelete,
    handleChatRename,
    clearError,
    refreshChats: () => loadChats(true)
  };
};

// Utility function to group chats by date
const groupChatsByDate = (chats) => {
  return chats.reduce((groups, chat) => {
    const date = new Date(chat.updated_at);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    let dateGroup;
    if (date.toDateString() === today.toDateString()) {
      dateGroup = 'Heute';
    } else if (date.toDateString() === yesterday.toDateString()) {
      dateGroup = 'Gestern';
    } else {
      dateGroup = date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    }

    if (!groups[dateGroup]) {
      groups[dateGroup] = [];
    }
    groups[dateGroup].push(chat);
    return groups;
  }, {});
};