import React from 'react';
import PropTypes from 'prop-types';

export const ResponseTypeToggle = ({ value, onChange }) => {
  return (
    <div className="flex items-center gap-2">
      <span className={`text-sm ${value === 'text' ? 'text-teal-500 font-medium' : 'text-gray-500'}`}>
        Text
      </span>
      <button
        onClick={() => onChange(value === 'text' ? 'audio' : 'text')}
        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white ${
          value === 'audio' ? 'bg-teal-500' : 'bg-gray-200'
        }`}
      >
        <span
          className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
            value === 'audio' ? 'translate-x-6' : 'translate-x-1'
          }`}
        />
      </button>
      <span className={`text-sm ${value === 'audio' ? 'text-teal-500 font-medium' : 'text-gray-500'}`}>
        Audio
      </span>
    </div>
  );
};

ResponseTypeToggle.propTypes = {
  value: PropTypes.oneOf(['text', 'audio']).isRequired,
  onChange: PropTypes.func.isRequired
};