// Individual field validators
const validators = {
  email: (value) => {
    if (!value?.trim()) {
      return 'Bitte gib deine E-Mail-Adresse ein.';
    }
    if (!value.includes('@') || !value.includes('.')) {
      return 'Bitte gib eine gültige E-Mail-Adresse ein.';
    }
    return null;
  },

  password: (value, { confirmPassword } = {}) => {
    if (!value) {
      return 'Bitte gib ein Passwort ein.';
    }
    if (value.length < 8) {
      return 'Das Passwort muss mindestens 8 Zeichen lang sein.';
    }
    if (confirmPassword !== undefined && value !== confirmPassword) {
      return 'Die Passwörter stimmen nicht überein.';
    }
    return null;
  },

  firstName: (value) => {
    if (!value?.trim()) {
      return 'Bitte gib deinen Vornamen ein.';
    }
    return null;
  },
  gender: (value) => {
  if (!value?.trim()) {
    return 'Bitte wähle dein Geschlecht aus.';
  }
  return null;
},
  lastName: (value) => {
    if (!value?.trim()) {
      return 'Bitte gib deinen Nachnamen ein.';
    }
    return null;
  }
};

// Validation schemas for different form types
const validationSchemas = {
  login: ['email', 'password'],
  registration: ['firstName', 'lastName', 'email', 'password', 'gender'],
  passwordReset: ['email'],
  newPassword: ['password'] // Added new schema for password reset form
};

/**
 * Validates form data based on the specified form type
 * @param {Object} formData - The form data to validate
 * @param {string} formType - The type of form ('login', 'registration', 'passwordReset', 'newPassword')
 * @returns {Object} - { isValid: boolean, error: string | null }
 */
export const validateForm = (formData, formType) => {
  const fieldsToValidate = validationSchemas[formType];

  if (!fieldsToValidate) {
    return { isValid: false, error: 'Invalid form type' };
  }

  for (const field of fieldsToValidate) {
    const validator = validators[field];
    if (!validator) continue;

    const error = validator(formData[field], formData);
    if (error) {
      return { isValid: false, error };
    }
  }

  return { isValid: true, error: null };
};